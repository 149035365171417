import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { EntityId } from "@reduxjs/toolkit";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhInputLabel from "@components/input/BhInputLabel";
import BhEmailInputWithUserDropdown from "@components/input/BhEmailInputWithUserDropdown";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { INewProjectForm } from "@/model/INewProjectForm";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { isValidEmail } from "@/utilities/jsUtilities";

interface Props {
  newProjectForm: INewProjectForm;
  setNewProjectForm: Dispatch<SetStateAction<INewProjectForm>>;
  users: Array<ISimpleValidUserAuthority>;
  setUsers: React.Dispatch<React.SetStateAction<Array<ISimpleValidUserAuthority>>>;
  companyId: EntityId;
}

const NewProjectAdminInput: FC<Props> = ({ companyId, newProjectForm, setNewProjectForm, users, setUsers }) => {
  const { t } = useTranslation();
  const [emailInput, setEmailInput] = useState<string>("");
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (company) {
      const projectAdministrators = (company && company.projects?.flatMap((project) => project.relatedUsers)) || [];
      const uniqueAdministrators = projectAdministrators.filter((admin, index, self) => {
        return index === self.findIndex((adm) => adm.userEntityId === admin.userEntityId);
      });
      setUsers(uniqueAdministrators);
    }
  }, []);

  const showValueCondition = (user: ISimpleValidUserAuthority) => {
    return !newProjectForm.relatedUsers.some((admin: ISimpleValidUserAuthority) => admin.username === user.username);
  };

  const addAdmin = () => {
    if (!isValidEmail(emailInput)) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          children: t("GLOBAL.EMAIL_INVALID")
        })
      );
      return;
    }
    const adminToAdd = users.find((user) => user.username === emailInput) || ({ username: emailInput.toLowerCase().replace(/[\s\u200B-\u200D\uFEFF]/g, "") } as ISimpleValidUserAuthority);
    const updatedArray = [...newProjectForm.relatedUsers, adminToAdd];
    setUsers(users.filter((user) => user.username !== adminToAdd.username));
    setNewProjectForm({ ...newProjectForm, relatedUsers: updatedArray });
    setEmailInput("");
  };

  return (
    <>
      <BhInputLabel>{t("COMPANY.SETTINGS.ADD_ADMINISTRATOR")}</BhInputLabel>
      <div className="flex items-center gap-2">
        <BhEmailInputWithUserDropdown
          emailInput={emailInput}
          setEmailInput={setEmailInput}
          values={users}
          showValueCondition={showValueCondition}
          placeholder={t("COMPANY.SETTINGS.ADD_ADMINISTRATOR") as string}
        />
        <BhPrimaryButton
          buttonProps={{
            onClick: addAdmin,
            disabled: !emailInput
          }}
        >
          {t("GLOBAL.ADD")}
        </BhPrimaryButton>
      </div>
    </>
  );
};

export default NewProjectAdminInput;
