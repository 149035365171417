import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";

// 2. Create a type for thunk dispatch
export type AppThunkDispatch = ThunkDispatch<RootState, any, UnknownAction>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
