import React, { FC, lazy } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { canOpenInPdftron } from "@/utilities/fileEntity/fileEntityUtilities";
import BhFileCardLarge from "@components/cards/BhFileCardLarge";
import { selectFileConfirmationFileById, selectFileConfirmationFilesIds } from "@/app/store/fileConfirmation/fileConfirmationFilesSlice";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileEntityId: EntityId;
}

const LazyPdftronContainer = lazy(() => import("@/views/home/project/detail/pdftron/PdftronContainer"));

const ConfirmationActionsPreviewViewFilePreview: FC<Props> = ({ fileEntityId }) => {
  const fileConfirmationFile = useAppSelector((state) => selectFileConfirmationFileById(state, fileEntityId));
  const fileConfirmationFilesIds = useAppSelector(selectFileConfirmationFilesIds);
  const navigate = useNavigate();

  if (!fileConfirmationFile) return null;

  const fileWithCorrectId = { ...fileConfirmationFile, id: fileConfirmationFile.fileEntityId };
  const canFileOpenInPdftron = fileWithCorrectId && canOpenInPdftron(fileWithCorrectId as unknown as IFileEntity);

  return (
    <>
      {canFileOpenInPdftron && (
        <LazyPdftronContainer
          fileEntityId={fileEntityId}
          fileEntityUuid={fileWithCorrectId.uuid}
          customBackButton={
            <BhIconButton
              icon={faTimes}
              buttonProps={{
                onClick: () =>
                  navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${fileConfirmationFile.projectId}/confirmation/${fileConfirmationFile.fileConfirmationId}/actions`)
              }}
            />
          }
          commentsDisabled={true}
        />
      )}
      {!canFileOpenInPdftron && (
        <div className="flex h-full w-full flex-col">
          <div className="items-top flex flex-row items-center border-b py-1">
            <div className="flex flex-1 flex-row items-center">
              <BhIconButton
                icon={faTimes}
                buttonProps={{
                  onClick: () =>
                    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${fileConfirmationFile.projectId}/confirmation/${fileConfirmationFile.fileConfirmationId}/actions`)
                }}
              />
            </div>
          </div>
          <div className="flex h-full w-full flex-col items-center justify-center">
            <BhFileCardLarge fileEntity={fileWithCorrectId as unknown as IFileEntity} openInNewTab={true} attachmentFileIds={fileConfirmationFilesIds} />
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationActionsPreviewViewFilePreview;
