import { ICompany, ICompanyComment, ICompanyModule, IEnabledForm, INewCompanyDTO } from "@/model/ICompany";
import { createAsyncThunk, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import {
  boAddCompanyAdministrator,
  boChangeCompanyAdministrator,
  boChangeCompanyAdminUserInvite,
  boChangeCompanyBillingSetupDone,
  boCloseProject,
  boDeleteChecklistBaseVersion,
  boDowngradePlan,
  boFetchAllCompanies,
  boFetchCompany,
  boFetchCompanyBilling,
  boFetchDowngradeInfo,
  boFetchProjectHistory,
  boFetchUpgradeInfo,
  boFindCompanyUsers,
  boGetCompanyFinbiteAuthCode,
  boGetInvitedUsersForCompany,
  boRemoveCompanyAdminInvite,
  boRemoveCompanyAdministratorPrivileges,
  boRemoveCompanyModule,
  boRemoveForm,
  boSaveCompany,
  boSaveCompanyBilling,
  boSaveCompanyComment,
  boSaveCompanyFinbiteAuthCode,
  boSaveCompanyLanguage,
  boSaveCompanyModule,
  boSaveCompanyModuleAdminSettings,
  boSaveCompanyTrialEnd,
  boSaveCompanyTrialStart,
  boSaveForm,
  boSaveNewProject,
  boSaveProjectBilling,
  boSaveProjectLanguage,
  boSaveProjectModule,
  boSuspendProject,
  boTransferChecklistBasesToCompany,
  boUpgradePlan
} from "@/api/backoffice/boCompaniesAPI";
import { RootState } from "@/app/store";
import { naturalSortByField, sortByAnyType } from "@/utilities/sortUtilities";
import {
  boChangeInvoice,
  boChangeInvoicePaymentDate,
  boCreditInvoice,
  boDownloadInvoice,
  boFetchMonthlyInvoiceOverview,
  boFetchYearlyInvoiceOverview,
  boGetPresignedUploadUrlForCustomSalesInvoice,
  boMarkInvoicePaid,
  boResendSalesInvoice,
  boSaveNewInvoice,
  boSendKmdInfA,
  boSendMonthlyInvoiceReport
} from "@/api/backoffice/boInvoicesAPI";
import { IYearlyInvoiceOverview } from "@/model/invoices/IYearlyInvoiceOverview";
import { ISalesInvoice } from "@/model/billing/ISalesInvoice";
import { IMonthlyInvoiceOverview } from "@/model/invoices/IMonthlyInvoiceOverview";
import { IInvoiceEmail } from "@/model/invoices/IInvoiceEmail";
import { IMonthlyInvoiceOverviewRow } from "@/model/invoices/IMonthlyInvoiceOverviewRow";
import { ICompanyBilling } from "@/model/billing/ICompanyBilling";
import { IBOCompanyTrialDateDTO } from "@components/backoffice/company/companyAndBilling/BOCompanyAndBillingGeneral";
import { IBOBillingSetupDoneDTO } from "@components/backoffice/company/companyAndBilling/BOCompanyKillswitch";
import { BhInvoiceOrderType, IBoFilter, initialFilter } from "@/model/backoffice/IBoFilter";
import { IBoSort, initialSort } from "@/model/backoffice/IBoSort";
import { IYearlyTrialOverview } from "@/model/trials/IYearlyTrialOverview";
import { boFindYearTrialOveriew } from "@/api/backoffice/boTrialsAPI";
import { IBoTransferChecklistsDTO } from "@components/backoffice/company/modulesAndTemplates/BOCompanyTransferChecklistsModal";
import { IBoDeleteChecklistBaseDTO } from "@components/backoffice/company/modulesAndTemplates/BOCompanyChecklists";
import { IBoFinbiteAuthCodeDTO } from "@components/backoffice/company/BOMaruFinbiteAuthCodeModal";
import { IProject, IProjectModule, ProjectStatus } from "@/model/IProject";
import { IProjectBilling } from "@/model/billing/IProjectBilling";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { ICompanyAdministratorChangeRequest } from "@/model/ICompanyAdministratorChangeRequest";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { IMonthlyTrialOverviewRow } from "@/model/trials/IMonthlyTrialOverviewRow";
import moment from "moment";

export interface BOState {
  companies: Array<ICompany>;
  company: ICompany;
  companyBilling: ICompanyBilling;
  sort: IBoSort;
  filter: IBoFilter;
  yearlyInvoiceOverview: IYearlyInvoiceOverview;
  monthlyInvoiceOverview: IMonthlyInvoiceOverview;
  yearlyTrialOverview: IYearlyTrialOverview;
}

const initialState: BOState = {
  companies: [] as Array<ICompany>,
  company: {} as ICompany,
  companyBilling: {} as ICompanyBilling,
  sort: initialSort,
  filter: initialFilter,
  yearlyInvoiceOverview: {} as IYearlyInvoiceOverview,
  monthlyInvoiceOverview: {} as IMonthlyInvoiceOverview,
  yearlyTrialOverview: {} as IYearlyTrialOverview
};

export const boFetchAllCompaniesAsync = createAsyncThunk("backoffice/company/list", async () => {
  return boFetchAllCompanies();
});

export const boFetchCompanyAsync = createAsyncThunk("backoffice/company", async (companyId: EntityId) => {
  return boFetchCompany(companyId);
});

export const boSaveNewCompanyAsync = createAsyncThunk("backoffice/company/save", async (company: INewCompanyDTO) => {
  return boSaveCompany(company);
});

export const boFetchCompanyBillingAsync = createAsyncThunk("backoffice/company/billing", async (companyId: EntityId) => {
  return boFetchCompanyBilling(companyId);
});

export const boSaveCompanyTrialStartAsync = createAsyncThunk("backoffice/company/trial/start", async (trialStartDTO: IBOCompanyTrialDateDTO) => {
  return boSaveCompanyTrialStart(trialStartDTO);
});

export const boSaveCompanyTrialEndAsync = createAsyncThunk("backoffice/company/trial/end", async (trialEndDTO: IBOCompanyTrialDateDTO) => {
  return boSaveCompanyTrialEnd(trialEndDTO);
});

export const boSaveCompanyBillingAsync = createAsyncThunk("backoffice/company/billing/save", async (companyBilling: ICompanyBilling) => {
  return boSaveCompanyBilling(companyBilling);
});

export const boFetchDowngradeInfoAsync = createAsyncThunk("backoffice/company/billing/downgrade/info", async (companyId: EntityId) => {
  return boFetchDowngradeInfo(companyId);
});

export const boFetchUpgradeInfoAsync = createAsyncThunk("backoffice/company/billing/upgrade/info", async (companyId: EntityId) => {
  return boFetchUpgradeInfo(companyId);
});

export const boDowngradePlanAsync = createAsyncThunk("backoffice/company/billing/downgrade", async (companyId: EntityId) => {
  return boDowngradePlan(companyId);
});

export const boUpgradePlanAsync = createAsyncThunk("backoffice/company/billing/upgrade", async (companyId: EntityId) => {
  return boUpgradePlan(companyId);
});

export const boChangeCompanyBillingSetupDoneAsync = createAsyncThunk("backoffice/company/billing/setup/done", async (billingSetupDoneDTO: IBOBillingSetupDoneDTO) => {
  return boChangeCompanyBillingSetupDone(billingSetupDoneDTO);
});

export const boSaveCompanyCommentAsync = createAsyncThunk("backoffice/company/comment/save", async (companyCommentDTO: ICompanyComment) => {
  return boSaveCompanyComment(companyCommentDTO);
});

export const boSaveCompanyLanguageAsync = createAsyncThunk("backoffice/company/language/save", async (dto: ICompany) => {
  return boSaveCompanyLanguage(dto.id, dto.language);
});
export const boFetchYearlyInvoiceOverviewAsync = createAsyncThunk("backoffice/invoices/yearly", async (year: string) => {
  return boFetchYearlyInvoiceOverview(year);
});

export const boFetchMonthlyInvoiceOverviewAsync = createAsyncThunk("backoffice/invoices/monthly", async (month: string) => {
  return boFetchMonthlyInvoiceOverview(month);
});

export const boDownloadInvoiceAsync = createAsyncThunk("backoffice/invoice/download", async (uuid: string) => {
  return boDownloadInvoice(uuid);
});

export const boCreditInvoiceAsync = createAsyncThunk("backoffice/invoice/mark/credit", async (invoice: ISalesInvoice) => {
  return boCreditInvoice(invoice);
});

export const boMarkInvoicePaidAsync = createAsyncThunk("backoffice/invoice/mark/paid", async (invoice: ISalesInvoice) => {
  return boMarkInvoicePaid(invoice);
});

export const boChangeInvoicePaymentDateAsync = createAsyncThunk("backoffice/invoice/change/date", async (invoice: ISalesInvoice) => {
  return boChangeInvoicePaymentDate(invoice);
});

export const boResendSalesInvoiceAsync = createAsyncThunk("backoffice/invoice/resend", async (invoice: ISalesInvoice) => {
  return boResendSalesInvoice(invoice);
});

export const boSendKmdInfAAsync = createAsyncThunk("backoffice/invoice/report/kmd", async (emails: IInvoiceEmail) => {
  return boSendKmdInfA(emails);
});

export const boSendMonthlyInvoiceReportAsync = createAsyncThunk("backoffice/invoice/report", async (emails: IInvoiceEmail) => {
  return boSendMonthlyInvoiceReport(emails);
});

export const boSaveNewInvoiceAsync = createAsyncThunk("backoffice/invoice/new", async (invoice: ISalesInvoice) => {
  return boSaveNewInvoice(invoice);
});
export const boChangeInvoiceAsync = createAsyncThunk("backoffice/invoice/change", async (invoice: ISalesInvoice) => {
  return boChangeInvoice(invoice);
});
export const boGetPresignedUploadUrlForCustomSalesInvoiceAsync = createAsyncThunk("backoffice/invoice/upload", async (contentType: string) => {
  return boGetPresignedUploadUrlForCustomSalesInvoice(contentType);
});

export const boFindYearTrialOveriewAsync = createAsyncThunk("backoffice/trials/yearly", async (year: string) => {
  return boFindYearTrialOveriew(year);
});
export const boGetCompanyFinbiteAuthCodeAsync = createAsyncThunk("backoffice/company/authCode", async (companyId: EntityId) => {
  return boGetCompanyFinbiteAuthCode(companyId);
});

export const boSaveCompanyFinbiteAuthCodeAsync = createAsyncThunk("backoffice/company/authCode/save", async (dto: IBoFinbiteAuthCodeDTO) => {
  return boSaveCompanyFinbiteAuthCode(dto);
});
export const boSaveCompanyModuleAsync = createAsyncThunk("/backoffice/company/modules/save", async (module: ICompanyModule) => {
  return boSaveCompanyModule(module);
});

export const boRemoveCompanyModuleAsync = createAsyncThunk("/backoffice/company/modules/remove", async (module: ICompanyModule) => {
  return boRemoveCompanyModule(module);
});

export const boSaveCompanyModuleAdminSettingsAsync = createAsyncThunk("/backoffice/company/modules/settings/save", async (module: ICompanyModule) => {
  return boSaveCompanyModuleAdminSettings(module);
});
export const boSaveProjectModuleAsync = createAsyncThunk("/backoffice/company/modules/project/module/save", async (module: IProjectModule) => {
  return boSaveProjectModule(module);
});

export const boTransferChecklistBasesToCompanyAsync = createAsyncThunk("backoffice/company/modules/checklists/transfer", async (dto: IBoTransferChecklistsDTO) => {
  return boTransferChecklistBasesToCompany(dto);
});

export const boDeleteChecklistBaseVersionAsync = createAsyncThunk("backoffice/company/modules/checklists/delete", async (dto: IBoDeleteChecklistBaseDTO) => {
  return boDeleteChecklistBaseVersion(dto);
});
export const boSaveCustomFormAsync = createAsyncThunk("backoffice/company/custom/forms/save", async (form: IEnabledForm) => {
  return boSaveForm(form);
});
export const boRemoveFormAsync = createAsyncThunk("backoffice/company/forms/remove", async (form: IEnabledForm) => {
  return boRemoveForm(form);
});

export const boFetchProjectHistoryAsync = createAsyncThunk("backoffice/company/project/history", async (projectId: EntityId) => {
  return boFetchProjectHistory(projectId);
});

export const boSuspendProjectAsync = createAsyncThunk("backoffice/company/project/suspend", async (project: IProject) => {
  return boSuspendProject(project);
});

export const boCloseProjectAsync = createAsyncThunk("backoffice/company/project/close", async (project: IProject) => {
  return boCloseProject(project);
});

export const boSaveProjectLanguageAsync = createAsyncThunk("backoffice/company/project/language/save", async (dto: IProject) => {
  return boSaveProjectLanguage(dto.id, dto.language);
});
export const boSaveProjectBillingAsync = createAsyncThunk("backoffice/company/project/billing", async (projectBilling: IProjectBilling) => {
  return boSaveProjectBilling(projectBilling);
});

export const boSaveNewProjectAsync = createAsyncThunk("backoffice/company/project/save", async (project: IProject) => {
  return boSaveNewProject(project);
});
export const boFindCompanyUsersAsync = createAsyncThunk("backoffice/company/users", async (companyId: EntityId) => {
  return boFindCompanyUsers(companyId);
});
export const boGetInvitedUsersForCompanyAsync = createAsyncThunk("backoffice/company/invited", async (companyId: EntityId) => {
  return boGetInvitedUsersForCompany(companyId);
});

export const boChangeCompanyAdministratorAsync = createAsyncThunk("backoffice/company/administrator/change", async ({ companyId, changedUser }: { companyId: EntityId; changedUser: ICompanyUser }) => {
  const companyAdministratorChangeRequest = {
    username: changedUser.username,
    accountManager: changedUser.accountManager,
    newProjectDefaultAdmin: changedUser.newProjectDefaultAdmin,
    checklistsManager: changedUser.checklistsManager,
    formsManager: changedUser.formsManager
  };
  return boChangeCompanyAdministrator(companyId, changedUser, companyAdministratorChangeRequest);
});

export const boChangeCompanyAdminUserInviteAsync = createAsyncThunk(
  "backoffice/company/invite/change",
  async ({ companyId, request }: { companyId: EntityId; request: ICompanyAdministratorChangeRequest }) => {
    const invitedUser = await boChangeCompanyAdminUserInvite(companyId, request);
    return { companyId: companyId, changedUser: invitedUser };
  }
);

export const boAddCompanyAdministratorAsync = createAsyncThunk("backoffice/company/admin/add", async ({ companyId, request }: { companyId: EntityId; request: ICompanyAdministratorChangeRequest }) => {
  return boAddCompanyAdministrator(companyId, request);
});

export const boRemoveCompanyAdministratorAsync = createAsyncThunk("backoffice/company/admin/remove", async ({ companyId, userId }: { companyId: EntityId; userId: EntityId }) => {
  const response = await boRemoveCompanyAdministratorPrivileges(companyId, userId);
  return { companyUsers: response };
});

export const boRemoveCompanyAdminInviteAsync = createAsyncThunk("backoffice/company/invite/remove", async (companyUser: ICompanyInvitedUser) => {
  return boRemoveCompanyAdminInvite(companyUser);
});

export const backofficeSlice = createSlice({
  name: "backoffice",
  initialState,
  reducers: {
    boSetFilters: (state, action: PayloadAction<IBoFilter>) => {
      state.filter = action.payload;
    },
    boSetSort: (state, action: PayloadAction<IBoSort>) => {
      state.sort = action.payload;
    },
    boSetCompanyBilling: (state, action: PayloadAction<ICompanyBilling>) => {
      state.companyBilling = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(boFetchAllCompaniesAsync.fulfilled, (state, action) => {
        state.companies = action.payload;
      })
      .addCase(boFetchCompanyAsync.fulfilled, (state, action) => {
        state.company = { ...action.payload, ...state.company };
      })
      .addCase(boSaveNewCompanyAsync.fulfilled, (state, action) => {
        const newCompany = action.payload;
        state.companies = [...state.companies, newCompany];
      })
      .addCase(boFetchCompanyBillingAsync.fulfilled, (state, action) => {
        state.companyBilling = action.payload;
      })
      .addCase(boSaveCompanyBillingAsync.fulfilled, (state, action) => {
        state.companyBilling = action.payload;
      })
      .addCase(boDowngradePlanAsync.fulfilled, (state, action) => {
        state.companyBilling = action.payload;
      })
      .addCase(boUpgradePlanAsync.fulfilled, (state, action) => {
        state.companyBilling = action.payload;
      })
      .addCase(boChangeCompanyBillingSetupDoneAsync.fulfilled, (state) => {
        state.company = { ...state.company, billingSetupDone: !state.company.billingSetupDone };
      })
      .addCase(boSaveCompanyCommentAsync.fulfilled, (state, action) => {
        const comment = action.payload;
        state.company = { ...state.company, comment };
      })
      .addCase(boFetchYearlyInvoiceOverviewAsync.fulfilled, (state, action) => {
        state.yearlyInvoiceOverview = action.payload;
      })
      .addCase(boFetchMonthlyInvoiceOverviewAsync.fulfilled, (state, action) => {
        state.monthlyInvoiceOverview = action.payload;
      })
      .addCase(boMarkInvoicePaidAsync.fulfilled, (state, action) => {
        state.monthlyInvoiceOverview = action.payload;
      })
      .addCase(boSaveCompanyLanguageAsync.fulfilled, (state, action) => {
        state.company.language = action.payload.value;
      })
      .addCase(boChangeInvoiceAsync.fulfilled, (state, action) => {
        const invoiceOverviewRow = action.payload;
        state.monthlyInvoiceOverview.rows = state.monthlyInvoiceOverview.rows.map((row) => {
          if (row.salesInvoice.id === invoiceOverviewRow.salesInvoice.id) {
            return invoiceOverviewRow;
          }
          return row;
        });
      })
      .addCase(boSaveNewInvoiceAsync.fulfilled, (state, action) => {
        state.monthlyInvoiceOverview.rows = [...state.monthlyInvoiceOverview.rows, action.payload];
      })
      .addCase(boCreditInvoiceAsync.fulfilled, (state, action) => {
        state.monthlyInvoiceOverview = action.payload;
      })
      .addCase(boSuspendProjectAsync.fulfilled, (state, action) => {
        state.company.projects = state.company.projects.map((project) => (project.id === action.payload.id ? { ...project, suspended: action.payload.suspended } : project));
      })
      .addCase(boCloseProjectAsync.fulfilled, (state, action) => {
        state.company.projects = state.company.projects.map((project) => (project.id === action.payload.id ? { ...project, closed: action.payload.closed } : project));
      })
      .addCase(boSaveProjectLanguageAsync.fulfilled, (state, action) => {
        state.company.projects = state.company.projects.map((project) => (project.id === action.meta.arg.id ? { ...project, language: action.payload.value } : project));
      })
      .addCase(boSaveProjectBillingAsync.fulfilled, (state, action) => {
        state.company.projects = state.company.projects.map((project) => (project.id === action.payload.projectId ? { ...project, billing: action.payload } : project));
      })
      .addCase(boSaveNewProjectAsync.fulfilled, (state, action) => {
        state.company.projects = [...state.company.projects, action.payload];
      })
      .addCase(boSaveCompanyTrialStartAsync.fulfilled, (state, action) => {
        state.company = { ...state.company, trialStart: action.payload.trialStart };
      })
      .addCase(boSaveCompanyTrialEndAsync.fulfilled, (state, action) => {
        state.company = { ...state.company, trialEnd: action.payload.trialEnd };
      })
      .addCase(boFindYearTrialOveriewAsync.fulfilled, (state, action) => {
        state.yearlyTrialOverview = action.payload;
      })
      .addCase(boGetCompanyFinbiteAuthCodeAsync.fulfilled, (state, action) => {
        state.company.companyInfo.finbiteAuthCode = action.payload.value;
      })
      .addCase(boSaveCompanyFinbiteAuthCodeAsync.fulfilled, (state, action) => {
        state.company.companyInfo.finbiteAuthCode = action.payload.value;
      })
      .addCase(boSaveCompanyModuleAsync.fulfilled, (state, action) => {
        state.company.modules = [...state.company.modules, action.payload];
      })
      .addCase(boRemoveCompanyModuleAsync.fulfilled, (state, action) => {
        state.company.modules = state.company.modules.filter((module) => module.id !== action.payload.id);
      })
      .addCase(boSaveCompanyModuleAdminSettingsAsync.fulfilled, (state, action) => {
        state.company.modules = state.company.modules.map((module) => (module.id === action.payload.id ? action.payload : module));
      })
      .addCase(boSaveProjectModuleAsync.fulfilled, (state, action) => {
        state.company.projects = state.company.projects.map((project) => {
          if (project.id === action.payload.projectId) {
            const changedModules = project.modules.map((module) => (module.id === action.payload.id ? action.payload : module));
            return { ...project, modules: [...changedModules] };
          } else {
            return project;
          }
        });
      })
      .addCase(boSaveCustomFormAsync.fulfilled, (state, action) => {
        state.company.enabledForms = [...state.company.enabledForms, action.payload];
      })
      .addCase(boRemoveFormAsync.fulfilled, (state, action) => {
        state.company.enabledForms = state.company.enabledForms.filter((form) => form.id !== action.payload.id);
      })
      .addCase(boDeleteChecklistBaseVersionAsync.fulfilled, (state, action) => {
        state.company.companyChecklistBases = state.company.companyChecklistBases.filter((cb) => cb.id !== action.payload.id);
      })
      .addCase(boFindCompanyUsersAsync.fulfilled, (state, action) => {
        state.company.realUsers = action.payload;
      })
      .addCase(boGetInvitedUsersForCompanyAsync.fulfilled, (state, action) => {
        state.company.invitedUsers = action.payload;
      })
      .addCase(boChangeCompanyAdministratorAsync.fulfilled, (state, action) => {
        state.company.realUsers = state.company.realUsers.map((user) => (user.userEntityId === action.payload.userEntityId ? { ...user, ...action.payload } : user));
      })
      .addCase(boChangeCompanyAdminUserInviteAsync.fulfilled, (state, action) => {
        state.company.invitedUsers = state.company.invitedUsers.map((user) => (user.username === action.payload.changedUser.username ? { ...user, ...action.payload.changedUser } : user));
      })
      .addCase(boAddCompanyAdministratorAsync.fulfilled, (state, action) => {
        const invitedAdmin = action.payload;
        if (invitedAdmin.userExists) {
          const existsInRealUsers = state.company.realUsers?.some((user) => user.username === invitedAdmin.username && user.companyAdmin);
          if (!existsInRealUsers) state.company.realUsers = [...state.company.realUsers, invitedAdmin as ICompanyUser];
        } else if (invitedAdmin.username) {
          const existsInInvitedUsers = state.company.invitedUsers?.some((user) => user.username === invitedAdmin.username && user.companyAdmin);
          if (!existsInInvitedUsers) state.company.invitedUsers = [...state.company.invitedUsers, invitedAdmin as ICompanyInvitedUser];
        }
      })
      .addCase(boRemoveCompanyAdministratorAsync.fulfilled, (state, action) => {
        const { companyUsers } = action.payload;
        state.company.realUsers = companyUsers;
      })
      .addCase(boRemoveCompanyAdminInviteAsync.fulfilled, (state, action) => {
        const responseUser = action.payload;
        state.company.invitedUsers = state.company.invitedUsers.map((user) => (user.username === responseUser.username ? { ...user, companyAdmin: false } : user));
      });
  }
});

export const { boSetFilters, boSetSort, boSetCompanyBilling } = backofficeSlice.actions;

export const boSelectAllCompanies = (state: RootState) => state.backoffice.companies;
export const boSelectCompany = (state: RootState) => state.backoffice.company;
export const boSelectCompanyBilling = (state: RootState) => state.backoffice.companyBilling;
export const boSelectFilter = (state: RootState) => state.backoffice.filter;
export const boSelectYearlyInvoiceOverview = (state: RootState) => state.backoffice.yearlyInvoiceOverview;
export const boSelectMonthlyInvoiceOverview = (state: RootState) => state.backoffice.monthlyInvoiceOverview;
export const boSelectSort = (state: RootState) => state.backoffice.sort;
export const boSelectYearlyTrialOverview = (state: RootState) => state.backoffice.yearlyTrialOverview;
export const boSelectAuthCode = (state: RootState) => state.backoffice.company.companyInfo.finbiteAuthCode;

export const boSelectCompanyAdmins = (state: RootState) => {
  if (state.backoffice.company) {
    const realUsers = state.backoffice.company.realUsers;
    return realUsers?.filter((user) => user.companyAdmin);
  } else {
    return new Array<ICompanyUser>();
  }
};

export const boSelectInvitedCompanyAdmins = (state: RootState) => {
  if (state.backoffice.company) {
    const invitedUsers = state.backoffice.company.invitedUsers;
    return invitedUsers?.filter((user) => user.companyAdmin);
  } else {
    return new Array<ICompanyUser>();
  }
};

export const boSelectAllCompaniesFilteredAndSorted = createSelector([boSelectAllCompanies, boSelectSort, boSelectFilter], (companies, sort, filter) => {
  const companiesFilter = filter.companiesFilter;
  const companiesSort = sort.companiesSort;
  return companies
    .slice()
    .filter((company) => {
      const nameFilter = filter?.searchQuery && filter.searchQuery.length > 0 ? company.name.toLowerCase().includes(filter.searchQuery.toLowerCase()) : true;
      const planFiltersSelected = companiesFilter.plans && companiesFilter.plans.filter((status) => status.selected);
      const plansFilter = planFiltersSelected?.length > 0 ? planFiltersSelected.some((plan) => plan.value === company.plan) : true;
      const statusFiltersSelected = companiesFilter.status && companiesFilter.status.filter((status) => status.selected);
      const statusFilter = statusFiltersSelected?.length > 0 ? statusFiltersSelected.some((status) => status.value === company.status) : true;
      return nameFilter && plansFilter && statusFilter;
    })
    .sort((a, b) => naturalSortByField(a, b, companiesSort.property, companiesSort.reversed));
});

export const boSelectAllMonthlyInvoiceOverviewRowsFilteredAndSorted = createSelector([boSelectMonthlyInvoiceOverview, boSelectSort, boSelectFilter], (monthlyInvoice, sort, filter) => {
  const invoicesFilter = filter.invoicesFilter;
  const invoicesSort = sort.invoicesSort;
  const rowsFilter = (row: IMonthlyInvoiceOverviewRow) => {
    const companyNameFilter = filter?.searchQuery && filter.searchQuery.length > 0 ? row.companyName.toLowerCase().includes(filter.searchQuery?.toLowerCase()) : true;
    const invoiceNumberFilter = filter?.searchQuery && filter.searchQuery.length > 0 ? row.salesInvoice.fullInvoiceNumber.includes(filter.searchQuery?.toLowerCase()) : true;
    const recipientNameFilter = filter?.searchQuery && filter.searchQuery.length > 0 ? row.salesInvoice.customerRecipient.toLowerCase().includes(filter.searchQuery?.toLowerCase()) : true;
    const planFiltersSelected = invoicesFilter.plans && invoicesFilter.plans.filter((plan) => plan.selected);
    const plansFilter = planFiltersSelected?.length > 0 ? planFiltersSelected.some((plan) => plan.value === row.companyPlan) : true;
    const orderTypeFiltersSelected = invoicesFilter.orderTypes && invoicesFilter.orderTypes.filter((orderType) => orderType.selected);
    const orderTypeFilter =
      orderTypeFiltersSelected?.length > 0
        ? orderTypeFiltersSelected.some((type) => {
          if (type.value === BhInvoiceOrderType.YEAR && (row.salesInvoice.type === BhInvoiceOrderType.YEAR || row.salesInvoice.type === BhInvoiceOrderType.UPGRADE)) return true;
          if (type.value === row.salesInvoice.type) return true;
          return false;
        })
        : true;
    const statusFiltersSelected = invoicesFilter.status && invoicesFilter.status.filter((status) => status.selected);
    const statusFilter = statusFiltersSelected?.length > 0 ? statusFiltersSelected.some((status) => status.value === row.salesInvoice.paid) : true;
    const invoiceDate = moment(row.salesInvoice.invoiceDate).startOf("day");
    const sinceDate = invoicesFilter?.date?.since ? moment(invoicesFilter.date.since).startOf("day") : null;
    const untilDate = invoicesFilter?.date?.until ? moment(invoicesFilter.date.until).endOf("day") : null;
    const dateFilter = (!sinceDate || invoiceDate >= sinceDate) && (!untilDate || invoiceDate <= untilDate);
    return (companyNameFilter || recipientNameFilter || invoiceNumberFilter) && plansFilter && orderTypeFilter && statusFilter && dateFilter;
  };
  return {
    ...monthlyInvoice,
    rows: monthlyInvoice.rows
      ?.slice()
      .filter((row) => rowsFilter(row))
      .sort((a, b) => {
        if (a.hasOwnProperty(invoicesSort.property)) {
          return sortByAnyType(a, b, invoicesSort.property, invoicesSort.reversed);
        } else {
          return sortByAnyType(a.salesInvoice, b.salesInvoice, invoicesSort.property, invoicesSort.reversed);
        }
      }),
    creditedRows: monthlyInvoice.creditedRows
      ?.slice()
      .filter((row) => rowsFilter(row))
      .sort((a, b) => naturalSortByField(a, b, invoicesSort.property, invoicesSort.reversed))
  };
});

export const boSelectCompanyProjectsFilteredAndSorted = createSelector([boSelectCompany, boSelectSort, boSelectFilter], (company, sort, filter) => {
  const companyProjectsFilter = filter.companyProjectsFilter;
  const companyProjectsSort = sort.companyProjectsSort;
  return company.projects
    ?.slice()
    .filter((project) => {
      const nameFilter = filter?.searchQuery && filter.searchQuery.length > 0 ? project.name.toLowerCase().includes(filter.searchQuery.toLowerCase()) : true;
      const statusFiltersSelected = companyProjectsFilter.status && companyProjectsFilter.status.filter((status) => status.selected);
      const statusFilter = statusFiltersSelected?.length > 0 ? statusFiltersSelected.some((status) => status.value === project.status) : true;
      const invoiceTypeFiltersSelected = companyProjectsFilter.invoiceType && companyProjectsFilter.invoiceType.filter((type) => type.selected);
      const invoiceTypeFilter = invoiceTypeFiltersSelected?.length > 0 ? invoiceTypeFiltersSelected.some((type) => type.value === project.billing.type) : true;
      const projectDate = new Date(project.created);
      const createdDateFilterValue = companyProjectsFilter.created ? new Date(companyProjectsFilter.created) : null;
      const dateFilter = !createdDateFilterValue || projectDate > createdDateFilterValue;
      if (companyProjectsFilter.showArchived === true) {
        return nameFilter && statusFilter && invoiceTypeFilter && dateFilter;
      }
      if (project.status === ProjectStatus.ARCHIVED) return false;
      return nameFilter && statusFilter && invoiceTypeFilter && dateFilter;
    })
    .sort((a, b) => {
      if (a.hasOwnProperty(companyProjectsSort.property)) {
        if (companyProjectsSort.property === "relatedUsers") {
          const uniqueRelatedUsersA = new Set(a[companyProjectsSort.property].map((user) => user.username));
          const uniqueRelatedUsersB = new Set(b[companyProjectsSort.property].map((user) => user.username));
          if (uniqueRelatedUsersA.size !== uniqueRelatedUsersB.size) {
            return uniqueRelatedUsersA.size > uniqueRelatedUsersB.size ? (companyProjectsSort.reversed ? -1 : 1) : companyProjectsSort.reversed ? 1 : -1;
          } else {
            return 0;
          }
        } else {
          return sortByAnyType(a, b, companyProjectsSort.property, companyProjectsSort.reversed);
        }
      } else {
        return sortByAnyType(a.billing, b.billing, companyProjectsSort.property, companyProjectsSort.reversed);
      }
    });
});

export const boSelectMonthlyTrialRowsFilteredAndSorted = createSelector([boSelectYearlyTrialOverview, boSelectFilter, boSelectSort], (trials, filter, sort) => {
  const trialsFilter = filter.trialsFilter;
  const trialsSort = sort.trialsSort;

  const filterTrials = (trial: IMonthlyTrialOverviewRow) => {
    const nameFilter = filter?.searchQuery && filter.searchQuery.length > 0 ? trial.companyName.toLowerCase().includes(filter.searchQuery.toLowerCase()) : true;
    const planFiltersSelected = trialsFilter.plans && trialsFilter.plans.filter((plan) => plan.selected);
    const plansFilter = planFiltersSelected?.length > 0 ? planFiltersSelected.some((plan) => plan.value === trial.companyPlan) : true;
    const trialStartDate = moment(trial.companyTrialStart).startOf("day");
    const trialEndDate = moment(trial.companyTrialEnd).startOf("day");
    const startDate = trialsFilter?.date?.since ? moment(trialsFilter.date.since).startOf("day") : null;
    const endDate = trialsFilter?.date?.until ? moment(trialsFilter.date.until).endOf("day") : null;
    const trialDatesFilter = (!startDate || trialStartDate >= startDate) && (!endDate || trialEndDate <= endDate);

    return nameFilter && trialDatesFilter && plansFilter;
  };

  const filteredRows = trials.rows?.map((row) => {
    const ongoingTrialsFiltered = row.ongoingTrials.filter(filterTrials).sort((a, b) => sortByAnyType(a, b, trialsSort.property, trialsSort.reversed));
    const endedTrialsFiltered = row.endedTrials.filter(filterTrials).sort((a, b) => sortByAnyType(a, b, trialsSort.property, trialsSort.reversed));

    return { ...row, ongoingTrials: [...ongoingTrialsFiltered], endedTrials: [...endedTrialsFiltered] };
  });

  return { ...trials, rows: filteredRows };
});

export default backofficeSlice.reducer;
