import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { FileEntityResource, FileEntityType, IFileEntity, IFileMatch, IRevisionMatcherFileEntity, IUploadFileEntity } from "@/model/files/IFileEntity";
import { fetchFilesAsync, filesModified } from "@/app/store/filesSlice";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import {
  addFilesToContainer,
  fetchPresignedUploadUrlUploadAndSaveFileMetaInfo,
  fetchPresignedUploadUrlUploadAndSaveRevisionFileMetaInfo,
  fetchPresignedUploadUrlUploadForCompanyAndSaveFileMetaInfo,
  uploadAndImportZipFile,
  uploadAndSaveContainerMetaInfo,
  uploadLargeFileAndSaveFileMetaInfo
} from "@/api/fileAPI";
import { asyncJobAdded, selectAnyAsyncJobsExecuting } from "@/app/store/asyncJobsSlice";
import { IAsyncJob } from "@/model/IAsyncJob";
import { parallelUploadCountStore } from "@/api/upload/uploadHelper";
import { uniqueId } from "lodash";
import { sleep, untilCondition } from "@/utilities/jsUtilities";

const uploadAdapter = createEntityAdapter<IUploadFileEntity, EntityId>({
  selectId: (a) => a.uuid,
  sortComparer: (a, b) => (a.uuid < b.uuid ? -1 : 1)
});

const uploadInitialState = uploadAdapter.getInitialState<{
  status: BhStateStatusType;
  modalOpen: boolean;
  uploadingFileCount: number;
}>({
  status: BhStateStatusType.INITIAL,
  modalOpen: false,
  uploadingFileCount: 0
});

export const uploadRevisionFilesAsync = createAsyncThunk(
  "upload/uploadFiles",
  async (dto: { fileMatches: Array<IFileMatch>; unusedFiles: Array<IRevisionMatcherFileEntity>; projectId: EntityId; currentDirId: EntityId }, { dispatch }) => {
    const { fileMatches, unusedFiles, projectId, currentDirId } = dto;
    if (fileMatches && fileMatches.length > 0) {
      for (const fileMatch of fileMatches) {
        dispatch(uploadFileRevisionAsync({ file: fileMatch.file.fileToUpload, projectId: projectId, revisionFile: fileMatch.matchingFile }));
        await sleep(30);
      }
    }
    if (unusedFiles && unusedFiles.length > 0) {
      for (const unusedFile of unusedFiles) {
        if (unusedFile.fileToUpload?.size && unusedFile.fileToUpload?.size > 4500000000) {
          dispatch(
            uploadLargeFileAsync({ projectId: projectId, parentDirId: currentDirId, fileSize: unusedFile.fileToUpload.size, fileName: unusedFile.fileToUpload.name, file: unusedFile.fileToUpload })
          );
        } else {
          dispatch(uploadFileAsync({ file: unusedFile.fileToUpload, projectId: projectId, currentDirId: currentDirId }));
        }
        await sleep(30);
      }
    }
  }
);

export const uploadFilesAsync = createAsyncThunk("upload/uploadFiles", async (dto: Array<{ file: any; directoryToUploadId: EntityId; projectId: EntityId }>, { dispatch }) => {
  for (const fileDTO of dto) {
    if (!fileDTO.directoryToUploadId) continue;
    if (fileDTO.file.name.includes(".asice") || fileDTO.file.name.includes(".bdoc") || fileDTO.file.name.includes(".edoc")) {
      dispatch(uploadContainerAsync({ file: fileDTO.file, projectId: fileDTO.projectId, currentDirId: fileDTO.directoryToUploadId }));
      // Files smaller than 4.5GB a.k.a. 4.19GiB
    } else if (fileDTO.file.size < 4500000000) {
      dispatch(uploadFileAsync({ file: fileDTO.file, projectId: fileDTO.projectId, currentDirId: fileDTO.directoryToUploadId }));
    } else {
      dispatch(uploadLargeFileAsync({ projectId: fileDTO.projectId, parentDirId: fileDTO.directoryToUploadId, fileSize: fileDTO.file.size, fileName: fileDTO.file.name, file: fileDTO.file }));
    }
    await sleep(30);
  }
});

export const uploadFileAsync = createAsyncThunk("upload/uploadFile", async (uploadObject: { file: File; projectId: EntityId; currentDirId: EntityId }, { getState, dispatch }) => {
  let uploadingStateFileEntity: IUploadFileEntity = {
    name: uploadObject.file.name,
    size: uploadObject.file.size,
    projectId: uploadObject.projectId,
    uuid: uniqueId(uploadObject.file.name),
    contentType: uploadObject.file.type,
    type: FileEntityType.FILE,
    uploading: true,
    uploaded: new Date().toISOString()
  } as IUploadFileEntity;

  if (uploadObject.currentDirId) uploadingStateFileEntity.parentFileEntityId = uploadObject.currentDirId as number;
  dispatch(uploadFileAdded(uploadingStateFileEntity));

  await untilCondition((_: any) => parallelUploadCountStore.getCount() < 5);

  const state: RootState = getState() as RootState;
  if (state.upload.entities[uploadingStateFileEntity.uuid]?.errorUploading) return;

  parallelUploadCountStore.increment();
  const uploadRequest = await fetchPresignedUploadUrlUploadAndSaveFileMetaInfo(uploadObject.file, uploadObject.projectId, uploadObject.currentDirId, dispatch, uploadingStateFileEntity);
  parallelUploadCountStore.decrement();

  return uploadRequest;
});

export const uploadCompanyAttachmentAsync = createAsyncThunk(
  "upload/company/uploadFile",
  async (
    uploadObject: {
      file: File;
      companyId: EntityId;
      resource?: FileEntityResource;
      resourceId?: EntityId;
    },
    { dispatch }
  ) => {
    let uploadingStateFileEntity: IUploadFileEntity = {
      name: uploadObject.file.name,
      size: uploadObject.file.size,
      companyId: uploadObject.companyId,
      uuid: uniqueId(uploadObject.file.name),
      contentType: uploadObject.file.type,
      type: FileEntityType.ATTACHMENT,
      uploading: true,
      uploaded: new Date().toISOString(),
      resource: uploadObject.resource ? uploadObject.resource : null,
      resourceId: uploadObject.resourceId ? uploadObject.resourceId : null
    } as IUploadFileEntity;

    dispatch(uploadFileAdded(uploadingStateFileEntity));

    await untilCondition((_: any) => parallelUploadCountStore.getCount() < 5);

    parallelUploadCountStore.increment();
    const uploadRequest = await fetchPresignedUploadUrlUploadForCompanyAndSaveFileMetaInfo(uploadObject.file, uploadObject.companyId, dispatch, uploadingStateFileEntity);
    parallelUploadCountStore.decrement();

    return uploadRequest;
  }
);

export const uploadAttachmentFileAsync = createAsyncThunk(
  "upload/uploadAttachmentFile",
  async (uploadObject: { file: File; projectId: EntityId; parentFileEntityId?: EntityId }, { getState, dispatch }) => {
    let uploadingStateFileEntity: IUploadFileEntity = {
      name: uploadObject.file.name,
      size: uploadObject.file.size,
      projectId: uploadObject.projectId,
      uuid: uniqueId(uploadObject.file.name),
      contentType: uploadObject.file.type,
      type: FileEntityType.ATTACHMENT,
      uploading: true,
      uploaded: new Date().toISOString()
    } as IUploadFileEntity;

    if (uploadObject.parentFileEntityId) uploadingStateFileEntity.parentFileEntityId = uploadObject.parentFileEntityId as number;
    dispatch(uploadFileAdded(uploadingStateFileEntity));

    await untilCondition((_: any) => parallelUploadCountStore.getCount() < 5);

    const state: RootState = getState() as RootState;
    if (state.upload.entities[uploadingStateFileEntity.uuid]?.errorUploading) return;

    parallelUploadCountStore.increment();
    const uploadRequest = await fetchPresignedUploadUrlUploadAndSaveFileMetaInfo(uploadObject.file, uploadObject.projectId, uploadObject.parentFileEntityId, dispatch, uploadingStateFileEntity).catch(
      () => {
        dispatch(uploadFileModified({ ...uploadingStateFileEntity, uploading: false, errorUploading: true }));
      }
    );
    parallelUploadCountStore.decrement();

    return uploadRequest;
  }
);

export const uploadFileRevisionAsync = createAsyncThunk("upload/uploadFileRevision", async (uploadObject: { file: File; projectId: EntityId; revisionFile: IFileEntity }, { getState, dispatch }) => {
  const isContainer = uploadObject.file.name.includes(".asice") || uploadObject.file.name.includes(".bdoc") || uploadObject.file.name.includes(".edoc");

  let uploadingStateFileEntity: IUploadFileEntity = {
    name: uploadObject.file.name,
    size: uploadObject.file.size,
    projectId: uploadObject.projectId,
    uuid: uniqueId(uploadObject.file.name),
    contentType: uploadObject.file.type,
    type: isContainer ? FileEntityType.CONTAINER : FileEntityType.FILE,
    uploading: true,
    uploaded: new Date().toISOString()
  } as IUploadFileEntity;

  if (uploadObject.revisionFile.parentFileEntityId) uploadingStateFileEntity.parentFileEntityId = uploadObject.revisionFile.parentFileEntityId;
  dispatch(uploadFileAdded(uploadingStateFileEntity));

  await untilCondition((_: any) => parallelUploadCountStore.getCount() < 5);

  const state: RootState = getState() as RootState;
  if (state.upload.entities[uploadingStateFileEntity.uuid]?.errorUploading) return;

  parallelUploadCountStore.increment();
  const uploadRequest = await fetchPresignedUploadUrlUploadAndSaveRevisionFileMetaInfo(uploadObject.file, uploadObject.projectId, uploadObject.revisionFile, dispatch, uploadingStateFileEntity);
  parallelUploadCountStore.decrement();

  return uploadRequest;
});

export const uploadLargeFileAsync = createAsyncThunk(
  "upload/uploadLargeFile",
  async (uploadObject: { projectId: EntityId; parentDirId: EntityId; fileSize: number; fileName: string; file: File }, { getState, dispatch }) => {
    const uploadRequest = await uploadLargeFileAndSaveFileMetaInfo(
      FileEntityType.FILE,
      uploadObject.projectId,
      uploadObject.parentDirId,
      uploadObject.fileSize,
      uploadObject.fileName,
      uploadObject.file,
      dispatch,
      getState
    );
    parallelUploadCountStore.decrement();

    return uploadRequest;
  }
);

export const uploadContainerAsync = createAsyncThunk("upload/uploadContainer", async (uploadObject: { file: File; projectId: EntityId; currentDirId: EntityId }, { getState, dispatch }) => {
  const uploadRequest = await uploadAndSaveContainerMetaInfo(FileEntityType.CONTAINER, uploadObject.file, uploadObject.projectId, uploadObject.currentDirId, dispatch, getState);
  parallelUploadCountStore.decrement();

  return uploadRequest;
});

export const uploadAndImportZipFileAsync = createAsyncThunk(
  "upload/uploadAndImportZipFile",
  async (uploadObject: { file: File; projectId: EntityId; isDocument: boolean; dirId?: EntityId }, { getState, dispatch }) => {
    const startedAsyncJob = await uploadAndImportZipFile(uploadObject.file, uploadObject.projectId, uploadObject.isDocument, dispatch, getState, uploadObject.dirId);
    parallelUploadCountStore.decrement();

    if (!startedAsyncJob) return;
    const asyncJob = { id: startedAsyncJob.jobId, status: startedAsyncJob.status } as IAsyncJob;
    dispatch(asyncJobAdded(asyncJob));
    return startedAsyncJob;
  }
);

export const addFilesToContainerAsync = createAsyncThunk(
  "upload/addFilesToContainer",
  async (uploadObject: { fileIds: Array<EntityId>; containerUuid: string; areFilesSelectedFromProject: boolean }, { dispatch }) => {
    const response = await addFilesToContainer(uploadObject.containerUuid, uploadObject.fileIds, uploadObject.areFilesSelectedFromProject);
    dispatch(filesModified(response));
    return response;
  }
);

const uploadSlice = createSlice({
  name: "upload",
  initialState: uploadInitialState,
  reducers: {
    uploadFileAdded: uploadAdapter.addOne,
    uploadFileUpdated: uploadAdapter.updateOne,
    uploadFileRemoved: uploadAdapter.removeOne,
    uploadFileModified: uploadAdapter.upsertOne,
    setUploadState: (state, action: PayloadAction<BhStateStatusType>) => {
      state.status = action.payload;
    },
    setUploadPercentage: (state, action: PayloadAction<{ id: EntityId; percentage: number }>) => {
      const uploadingFileEntity = state.entities[action.payload.id];
      if (uploadingFileEntity) {
        uploadingFileEntity.percentage = action.payload.percentage;
      }
    },
    setUploadModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modalOpen = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(uploadFileAsync.pending, (state) => {}).addCase(fetchFilesAsync.fulfilled, (state, action) => {});
  }
});

export const { uploadFileAdded, uploadFileUpdated, uploadFileRemoved, uploadFileModified, setUploadState, setUploadPercentage, setUploadModalOpen } = uploadSlice.actions;

export const { selectAll: selectAllUploads, selectById: selectUploadById, selectIds: selectUploadIds } = uploadAdapter.getSelectors((state: RootState) => state.upload);

export const selectUploadStatus = (state: RootState) => state.upload.status;
export const selectUploadModalOpen = (state: RootState) => state.upload.modalOpen || selectAnyAsyncJobsExecuting(state);
export const selectAllUploadsSorted = createSelector(selectAllUploads, (files) => [...files].sort((a, b) => (a.uploaded < b.uploaded ? 1 : -1)));
export const selectFilesUploadingLength = createSelector(selectAllUploads, (files) => files.filter((fileEntity) => fileEntity.uploading).length);
export const selectFilesUploadingAndNotProcessingLength = createSelector(
  selectAllUploads,
  (files) => files.filter((fileEntity) => fileEntity.uploading && (fileEntity.percentage < 99 || !fileEntity.percentage)).length
);

export default uploadSlice.reducer;
