import React, { FC, useCallback } from "react";
import { EntityId } from "@reduxjs/toolkit";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileEntityId: EntityId;
  parentFileEntityId: EntityId;
  projectId: EntityId;
  isForm?: boolean;
}

const BackButtonToDirectoryView: FC<Props> = ({ fileEntityId, parentFileEntityId, projectId, isForm }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { previousState } = location.state || ({ previousState: undefined } as any);

  const goBack = useCallback(() => {
    if (isForm) {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/dir/${parentFileEntityId}`, { state: { highlight: fileEntityId } });
      return;
    }
    if (previousState) {
      const stateObject = previousState === "directoryView" ? { state: { highlight: fileEntityId } } : undefined;
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/dir/${parentFileEntityId}`, stateObject);
    } else {
      if (document.referrer && document.referrer.indexOf('eparaksts')) {
        navigate(-3);
      }
      navigate(-1);
    }
  }, [fileEntityId, parentFileEntityId, projectId]);

  return <BhIconButton icon={faArrowLeft} buttonProps={{ onClick: goBack }} />;
};

export default BackButtonToDirectoryView;
