import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { isValidEmail } from "@/utilities/jsUtilities";
import BhInputLabel from "@components/input/BhInputLabel";
import BhEmailInputWithUserDropdown from "@components/input/BhEmailInputWithUserDropdown";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownButton from "@components/dropdown/BhDropdownButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { IRoleAuthName, ROLES } from "@/model/party/IRoleAuthName";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import PartyTemplateNewUserPrivilegeDropdown from "@/views/home/company/detail/companySettingsPartyTemplates/PartyTemplateNewUserPrivilegeDropdown";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { IPredefinedWorkGroupUser } from "@/model/IUserAuthority";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useTranslation } from "react-i18next";
import { EntityId } from "@reduxjs/toolkit";
import { fetchCompanyUsersForNewPredefinedPartyAsync, selectAllCompanyUsers, selectCompanyPredefinedPartyUsersById } from "@/app/store/companiesSlice";
import BhFixedDropdownParent from "@components/dropdown/BhFixedDropdownParent";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";

interface Props {
  userToAdd: IPredefinedWorkGroupUser;
  setUserToAdd: Dispatch<SetStateAction<IPredefinedWorkGroupUser>>;
  userDoesNotExist: boolean;
  partyAlreadyAdded: boolean;
  companyId: EntityId;
  predefinedPartyId: EntityId;
}

const PartyTemplateAddNewUserModalContent: FC<Props> = ({ userToAdd, setUserToAdd, userDoesNotExist, partyAlreadyAdded, companyId, predefinedPartyId }) => {
  const { t } = useTranslation();
  const [emailInput, setEmailInput] = useState<string>("");
  const [role, setRole] = useState<string>(t("COMPANY.SETTINGS.PARTY_TEMPLATES.ADD_USER.SELECT_AUTH") as string);
  const companyUsers = useAppSelector((state) => selectAllCompanyUsers(state, companyId));
  const predefinedPartyUsers = useAppSelector((state) => selectCompanyPredefinedPartyUsersById(state, [companyId, predefinedPartyId]));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCompanyUsersForNewPredefinedPartyAsync(companyId));

    return function cleanup() {
      setEmailInput("");
    };
  }, []);

  useEffect(() => {
    if (userToAdd.username.length === 0) setEmailInput("");
  }, [userToAdd]);

  useEffect(() => {
    if (emailInput.length > 3 && isValidEmail(emailInput)) {
      setUserToAdd({ ...userToAdd, username: emailInput });
    }
  }, [emailInput]);

  useEffect(() => {
    if (userToAdd.username.length > 5 && userDoesNotExist) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          translateCode: "GLOBAL.USER_MISSING"
        })
      );
    }
    if (partyAlreadyAdded) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          translateCode: "PARTY.ALREADY_WG_USER"
        })
      );
    }
    setRole(getSelectedRoleName());
  }, [userToAdd]);

  const userToAddAuthProperties: Array<{ property: keyof IPredefinedWorkGroupUser; header: string }> = [
    { property: "filesAuthLevel", header: t("GLOBAL.DRAWINGS") },
    { property: "documentsAuthLevel", header: t("GLOBAL.DOCUMENTS") },
    { property: "modelAuthLevel", header: t("PARTY.MODEL") },
    { property: "actsAuthLevel", header: t("PARTY.ACTS") },
    { property: "contractAuthLevel", header: t("PARTY.CONTRACTS") }
  ];

  const getSelectedRoleName = useCallback(() => {
    const role = ROLES.find((role) => {
      return role.documents === userToAdd.documentsAuthLevel && role.files === userToAdd.filesAuthLevel && role.models === userToAdd.modelAuthLevel;
    }) || { name: "ROLE.CUSTOM" };
    return t(role.name);
  }, [userToAdd]);

  const onAuthSetSelect = (role: IRoleAuthName) => {
    setUserToAdd({
      ...userToAdd,
      filesAuthLevel: role.files,
      documentsAuthLevel: role.documents,
      modelAuthLevel: role.models
    });
  };

  const showUserValueCondition = (user: ISimpleValidUserAuthority) => {
    return !predefinedPartyUsers?.some((u) => u.username.toLowerCase() === user.username.toLowerCase());
  };

  return (
    <div>
      <div className="w-full">
        <BhInputLabel>{t("WORKGROUP.NEW.USERS.EMAIL")}</BhInputLabel>
        <div className="flex items-center gap-2">
          <BhFixedDropdownParent>
            <BhEmailInputWithUserDropdown
              emailInput={emailInput}
              setEmailInput={setEmailInput}
              values={companyUsers}
              showValueCondition={showUserValueCondition}
              placeholder={t("WORKGROUP.NEW.USERS.EMAIL.PLACEHOLDER") as string}
            />
          </BhFixedDropdownParent>
        </div>
      </div>
      <p className="mt-16 font-bold">{t("WORKGROUP.NEW.USERS.AUTHORITIES")}</p>
      <div className="bh-border-white mb-40 flex w-full flex-row rounded border lg:flex-col">
        <div className="bh-bg-smoke-50 flex w-1/4 flex-col p-4 lg:w-full">
          <div className="px-2">{t("SHARE.HEADER.RIGHTS")}</div>
          <div className="flex">
            <BhDropdown
              button={<BhDropdownButton reversed={true} title="" value={role} />}
              menu={<BhDropdownMenu values={ROLES} textProperty="name" type={BhDropdownTypeEnum.STRING} onSelect={onAuthSetSelect} translateValues={true} />}
            />
          </div>
        </div>
        <div className="flex w-3/4 w-full flex-row flex-nowrap">
          {userToAddAuthProperties.map((property) => (
            <PartyTemplateNewUserPrivilegeDropdown key={property.property} userToAdd={userToAdd} setUserToAdd={setUserToAdd} property={property.property} header={property.header} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartyTemplateAddNewUserModalContent;
