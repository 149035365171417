import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { ISignInvite } from "@/model/ISignInvite";
import { RootState } from "@/app/store";
import { fetchAllUnsignedSignInvitesInProject, fetchUserInvites, fetchUserUnsignedSignInvites } from "@/api/signInviteAPI";
import { handleSignInvitesToSignatureHolders } from "@/app/store/signaturesSlice";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { ISignInviteContainer } from "@/model/container/ISignInviteContainer";
import { ISignInvitesFilter } from "@/model/ISignInvitesFilter";
import { initialSignInvitesSort, ISignInvitesSort } from "@/model/ISignInvitesSort";
import { naturalSortByField } from "@/utilities/sortUtilities";
import { intersection, removeDuplicates } from "@/utilities/jsUtilities";

const signInviteAdapter = createEntityAdapter<ISignInvite, EntityId>({
  selectId: (a) => a.id
});

const signInviteInitialState = signInviteAdapter.getInitialState<{
  status: BhStateStatusType;
  signInviteListStatus: BhStateStatusType;
  allProjectUnsignedContainers: Array<ISignInviteContainer>;
  filter: ISignInvitesFilter;
  sort: ISignInvitesSort;
}>({
  status: BhStateStatusType.INITIAL,
  signInviteListStatus: BhStateStatusType.INITIAL,
  allProjectUnsignedContainers: [],
  filter: {
    name: "",
    queueEnabled: false,
    initiator: [],
    signer: [],
    folderIds: []
  } as ISignInvitesFilter,
  sort: initialSignInvitesSort
});

export const fetchUserUnsignedSignInvitesAsync = createAsyncThunk("signInvite/fetchUserUnsignedSignInvites", async (_, { dispatch }) => {
  let signInvites = await fetchUserUnsignedSignInvites();
  dispatch(handleSignInvitesToSignatureHolders(signInvites));
  return signInvites;
});

export const fetchAllUnsignedInvitesInProjectAsync = createAsyncThunk("signInvite/fetchAllUnsignedInvitesInProject", async (projectId: EntityId) => {
  return fetchAllUnsignedSignInvitesInProject(projectId);
});

export const fetchUserSignedInvitesAsync = createAsyncThunk("signatures/fetchUserSignedInvites", async (projectId: EntityId) => {
  return fetchUserInvites(projectId, true);
});

export const fetchUserDeclinedInvitesAsync = createAsyncThunk("signatures/fetchUserDeclinedInvites", async (projectId: EntityId) => {
  return fetchUserInvites(projectId, false, true);
});

const signInvitesSlice = createSlice({
  name: "signInvites",
  initialState: signInviteInitialState,
  reducers: {
    signInvitesFilterSet: (state, action: PayloadAction<ISignInvitesFilter>) => {
      state.filter = action.payload;
    },
    signInvitesSenderFilterSet: (state, action: PayloadAction<string>) => {
      const alreadyExists = state.filter.initiator?.some((initiator: string) => initiator?.toLowerCase() === action.payload.toLowerCase());
      if (alreadyExists) {
        state.filter.initiator = state.filter.initiator.filter((username) => username.toLowerCase() !== action.payload.toLowerCase());
      } else {
        state.filter.initiator = [...state.filter.initiator, action.payload];
      }
    },
    signInvitesSignerFilterSet: (state, action: PayloadAction<string>) => {
      const alreadyExists = state.filter.signer?.some((signer: string) => signer?.toLowerCase() === action.payload.toLowerCase());
      if (alreadyExists) {
        state.filter.signer = state.filter.signer.filter((username) => username.toLowerCase() !== action.payload.toLowerCase());
      } else {
        state.filter.signer = [...state.filter.signer, action.payload];
      }
    },
    signInvitesSenderFilterCleared: (state) => {
      state.filter.initiator = [];
    },
    signInvitesSignerFilterCleared: (state) => {
      state.filter.signer = [];
    },
    signInvitesFilterCleared: (state) => {
      state.filter = {
        name: "",
        queueEnabled: false,
        initiator: [],
        signer: [],
        folderIds: []
      } as ISignInvitesFilter;
    },
    signInvitesSortSet: (state, action: PayloadAction<ISignInvitesSort>) => {
      state.sort = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUserUnsignedSignInvitesAsync.pending, (state) => {
        state.signInviteListStatus = BhStateStatusType.PENDING;
      })
      .addCase(fetchUserUnsignedSignInvitesAsync.fulfilled, (state, action) => {
        state.signInviteListStatus = BhStateStatusType.SUCCESS;
        const signInviteEntities = action.payload;
        signInviteAdapter.removeAll(state);
        signInviteAdapter.upsertMany(state, signInviteEntities);
      })
      .addCase(fetchUserSignedInvitesAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchUserSignedInvitesAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        signInviteAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchUserDeclinedInvitesAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchUserDeclinedInvitesAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        signInviteAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchAllUnsignedInvitesInProjectAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchAllUnsignedInvitesInProjectAsync.fulfilled, (state, action) => {
        state.signInviteListStatus = BhStateStatusType.SUCCESS;
        state.status = BhStateStatusType.SUCCESS;
        const containers = action.payload;
        state.allProjectUnsignedContainers = containers;
      });
  }
});

export const {
  signInvitesFilterSet,
  signInvitesSenderFilterSet,
  signInvitesSignerFilterSet,
  signInvitesSenderFilterCleared,
  signInvitesSignerFilterCleared,
  signInvitesFilterCleared,
  signInvitesSortSet
} = signInvitesSlice.actions;

export const { selectAll: selectAllSignInvites, selectById: selectSignInviteById, selectIds: selectSignInviteIds } = signInviteAdapter.getSelectors((state: RootState) => state.signInvites);

export const selectSignInvitesStatus = (state: RootState) => state.signInvites.status;
export const selectSignInvitesListStatus = (state: RootState) => state.signInvites.signInviteListStatus;
export const selectSignInvitesFilter = (state: RootState) => state.signInvites.filter;
export const selectSignInvitesSort = (state: RootState) => state.signInvites.sort;

export const selectAllProjectUnsignedContainers = (state: RootState) => state.signInvites.allProjectUnsignedContainers.filter((signInviteContainer) => !signInviteContainer.container.isDeleted);
export const selectContainerId = (state: RootState, id: EntityId) => id;
export const selectSignInvitesContainerById = createSelector([selectAllProjectUnsignedContainers, selectContainerId], (invitesContainers, id) => {
  return invitesContainers.find((invitesContainer) => invitesContainer.container.id === id);
});

export const selectAllProjectFilteredAndSortedSignInvites = createSelector([selectAllProjectUnsignedContainers, selectSignInvitesFilter, selectSignInvitesSort], (allInvites, filter, sort) => {
  const containerSort = sort.property === ("container.name" as keyof ISignInvite) ? "name" : sort.property;
  return allInvites
    .filter((invitesContainer: ISignInviteContainer) => {
      const signerEmails = invitesContainer.signInvites.flatMap((invite) => invite.username);
      const signersFilter = filter.signer.length > 0 ? intersection(signerEmails, filter.signer).length === filter.signer.length : true;
      const initiatorFilter = filter.initiator.length > 0 ? filter.initiator.some((initiator) => invitesContainer.container.createdBy === initiator) : true;
      const nameFilter = filter.name ? invitesContainer.container.name.toLowerCase().includes(filter?.name.toLowerCase()) : true;
      const createdFromFilter = filter?.createdFrom ? invitesContainer.container.created > filter.createdFrom : true;
      const createdUntilFilter = filter?.createdUntil ? invitesContainer.container.created < filter.createdUntil : true;
      const queueEnabledFilter = filter?.queueEnabled ? invitesContainer.container.queueEnabled : true;
      const folderFilter = filter.folderIds.length > 0 ? filter.folderIds.some((id) => invitesContainer.container.parentFileEntityId === id) : true;
      return signersFilter && initiatorFilter && nameFilter && createdFromFilter && createdUntilFilter && queueEnabledFilter && folderFilter;
    })
    .sort((a, b) => {
      return naturalSortByField(a.container, b.container, containerSort, sort.reversed);
    });
});

export const selectProjectIdForSignInvites = (state: RootState, projectId: EntityId) => projectId;
export const selectSignInvitesForProject = createSelector([selectAllSignInvites, selectProjectIdForSignInvites], (signInvites, projectId) =>
  signInvites.filter((signInvite) => signInvite.projectId === projectId && !signInvite.deleted)
);

const selectAllSignInvitesForProjectFilteredAndSorted = createSelector(
  [selectSignInvitesForProject, selectProjectIdForSignInvites, selectSignInvitesFilter, selectSignInvitesSort],
  (signInvites, id, filter, sort) => {
    const filteredSignInvites = signInvites.filter((signInvite) => {
      const nameFilter = signInvite.container.name.toLowerCase().includes(filter.name.toLowerCase());
      const senderFilter = filter.initiator.length > 0 ? filter.initiator.some((sender) => sender?.toLowerCase() === signInvite.createdBy.toLowerCase()) : true;
      return nameFilter && senderFilter;
    });
    return filteredSignInvites.sort((a, b) => naturalSortByField(a, b, sort.property, sort.reversed));
  }
);

export const selectUnsignedSignInvitesForProjectFilteredAndSorted = createSelector([selectAllSignInvitesForProjectFilteredAndSorted], (signInvites) => {
  return signInvites.filter((signInvite) => !signInvite.signed);
});

export const selectSignedSignInvitesForProjectFilteredAndSorted = createSelector([selectAllSignInvitesForProjectFilteredAndSorted], (signInvites) => {
  return signInvites.filter((signInvite) => signInvite.signed);
});

export const selectCountOfSignedSignInvitesForProject = createSelector([selectSignInvitesForProject], (signInvites) => {
  return signInvites.filter((signInvite) => signInvite.signed).length;
});

export const selectDeclinedSignInvitesForProjectFilteredAndSorted = createSelector([selectAllSignInvitesForProjectFilteredAndSorted], (signInvites) => {
  return signInvites.filter((signInvite) => !signInvite.signed && signInvite.declined);
});

export const selectCountOfDeclinedSignInvitesForProject = createSelector([selectSignInvitesForProject], (signInvites) => {
  return signInvites.filter((signInvite) => !signInvite.signed && signInvite.declined).length;
});

export const selectSenderFilterDropdownValuesForUserUnsignedSignInvites = createSelector([selectSignInvitesForProject], (invites) => {
  return removeDuplicates(invites.map((invite) => invite.createdBy).sort());
});
export const selectSenderFilterDropdownValuesForUserSignedSignInvites = createSelector([selectSignInvitesForProject], (invites) => {
  const signedInvites = invites.filter((invite) => invite.signed);
  return removeDuplicates(signedInvites.map((invite) => invite.createdBy).sort());
});
export const selectSenderFilterDropdownValuesForUserDeclinedSignInvites = createSelector([selectSignInvitesForProject], (invites) => {
  const declinedInvites = invites.filter((invite) => invite.declined);
  return removeDuplicates(declinedInvites.map((invite) => invite.createdBy).sort());
});
export const selectSenderFilterDropdownValuesForAllProjectUnsignedInvites = createSelector([selectAllProjectUnsignedContainers], (containers) => {
  return removeDuplicates(containers.map((container) => container.container.createdBy).sort());
});
export const selectSignersFilterDropdownValuesForAllProjectUnsignedInvites = createSelector([selectAllProjectUnsignedContainers], (containers) => {
  const signInvites = containers.flatMap((container) => container.signInvites);
  return removeDuplicates(signInvites.map((invite) => invite.username).sort());
});

export const selectCountOfMyUnsignedSignInvitesForProject = (projectId: EntityId) =>
  createSelector(
    selectAllSignInvites,
    (signInvites) => signInvites.filter((signInvite) => signInvite.projectId === projectId && !signInvite.signed && !signInvite.declined && !signInvite.deleted).length
  );

export default signInvitesSlice.reducer;
