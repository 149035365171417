import React, { FC, lazy, useEffect, useState } from "react";

import { et, ru } from "date-fns/locale";
import { registerLocale } from "react-datepicker";
import OldUrlRedirect from "@/views/home/OldUrlRedirect";
import "react-tooltip/dist/react-tooltip.css";
import BauhubAppRouter from "@/views/home/BauhubAppRouter";
import BhFullPageLoading from "@components/loading/BhFullPageLoading";
import { Route, Routes, useParams } from "react-router-dom";
import NotFound from "@/views/global/NotFound";

registerLocale("et", et);
registerLocale("ru", ru);

const LazyXeokitMobileViewer = lazy(() => import("@/views/home/project/detail/xeokit/mobile/XeokitMobileViewer"));
const LazyXeokitMobileViewerEmulator = lazy(() => import("@/views/home/project/detail/xeokit/mobile/emulator/XeokitMobileViewerEmulator"));

const BauhubAppRouterWithOldUrlRedirect: FC = () => {
  const [needsRedirect, setNeedsRedirect] = useState<boolean | undefined>();

  useEffect(() => {
    if (needsRedirect === undefined || needsRedirect === true) {
      const path = window.location.hash;
      const isOldUrl = path.startsWith("#");
      setNeedsRedirect(isOldUrl);
    }
  }, [needsRedirect]);

  if (needsRedirect === undefined) return <BhFullPageLoading />;
  if (needsRedirect) return <OldUrlRedirect />;

  // FOR XEOKIT EMULATION
  function XeokitMobileViewerEmulatorWithParams() {
    const { folderFileEntityId } = useParams();
    if (folderFileEntityId) {
      return <LazyXeokitMobileViewerEmulator folderFileEntityId={folderFileEntityId} />;
    }
    return <NotFound />;
  }

  return (
    <>
      <Routes>
        <Route path="*" element={<BauhubAppRouter />} />
        <Route path="bim/viewer" element={<LazyXeokitMobileViewer />} />
        <Route path="bim/viewer/emulate/:folderFileEntityId" element={<XeokitMobileViewerEmulatorWithParams />} />
      </Routes>
    </>
  );
};

export default BauhubAppRouterWithOldUrlRedirect;
