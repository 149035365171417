import { createAction, createAsyncThunk, createEntityAdapter, createReducer, createSelector, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { IWorkGroup, WorkGroupType } from "@/model/IWorkGroup";
import { createFileTree } from "@/utilities/fileEntityUtilities";

import {
  deleteWorkGroup,
  fetchProjectWorkGroupPrivileges,
  fetchProjectWorkGroups,
  fetchProjectWorkGroupSelectedFileTree,
  fetchWorkGroup,
  fetchWorkGroupTaskBoards,
  saveAddAllUsersToTaskBoard,
  saveAddUserToTaskBoard,
  saveAddWorkGroupToTaskBoard,
  saveChangeWorkGroupType,
  saveNewParty,
  saveRemoveAllUsersFromTaskBoard,
  saveRemoveUserFromTaskBoard,
  saveRemoveUserFromWorkGroup,
  saveRemoveWorkGroupFromTaskBoard,
  saveRenameWorkGroup,
  saveWorkGroupFileDeSelected,
  saveWorkGroupFileSelected,
  saveWorkGroupUserAuthorityChange,
  saveWorkGroupUserInvite
} from "@/api/partyAPI";
import { IWorkGroupFilter, IWorkGroupFilterTypeDropdown } from "@/model/IWorkGroupFilter";
import { initialPartiesSort, IPartiesSort } from "@/model/IPartiesSort";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import { IPartyPrivilegeChangeDTO } from "@/model/party/IPartyPrivilegeChangeDTO";
import { SubResource } from "@/model/IUserAuthority";
import { IPartyUserTaskBoardChangeDTO } from "@/model/party/IPartyUserTaskBoardChangeDTO";
import { IFileTreeDTO } from "@/model/files/IFileTreeDTO";
import { FileEntityBranch, FileEntityType } from "@/model/files/IFileEntity";
import { IPartyDTO } from "@/model/party/IPartyDTO";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { naturalSortByField, sortByFullNameAndEmail } from "@/utilities/sortUtilities";
import { saveMaruValuation } from "@/api/maru/maruAPI";
import { IMaruValuation } from "@/model/maru/IMaruValuation";

export const projectWorkGroupAdapter = createEntityAdapter<IWorkGroup>();

export interface IProjectWorkGroupsState {
  currentUsersAndInvites: Array<IPartyPrivilegeListItemDTO>;
  currentFileTree: Array<IFileTreeDTO>;
  selectedFileIds: Array<{ id: EntityId; branch: FileEntityBranch }>;
  status: BhStateStatusType;
  filter: IWorkGroupFilter;
  sort: IPartiesSort;
  modals: {
    addUserModalShown: boolean;
    removeUserModalShown: boolean;
  };
}

export const projectWorkGroupInitialState = projectWorkGroupAdapter.getInitialState<IProjectWorkGroupsState>({
  currentUsersAndInvites: [] as Array<IPartyPrivilegeListItemDTO>,
  currentFileTree: [] as Array<IFileTreeDTO>,
  selectedFileIds: [] as Array<{ id: EntityId; branch: FileEntityBranch }>,
  status: BhStateStatusType.IDLE,
  filter: {
    name: "",
    typeDropdownValues: [
      { type: WorkGroupType.CLIENT, translation: "PARTY.CLIENT", selected: false },
      { type: WorkGroupType.SUBCONTRACTOR, translation: "PARTY.SUBCONTRACTOR", selected: false }
    ]
  },
  sort: initialPartiesSort,
  modals: {
    addUserModalShown: false,
    removeUserModalShown: false
  }
});

export const fetchProjectWorkGroupsAsync = createAsyncThunk("project/fetchProjectWorkGroups", async (projectId: EntityId, { getState }) => {
  return fetchProjectWorkGroups(projectId);
});

export const fetchWorkGroupAsync = createAsyncThunk("project/fetchWorkGroup", async (dto: { projectId: EntityId; partyId: EntityId }, { getState }) => {
  return fetchWorkGroup(dto.projectId, dto.partyId);
});

export const fetchWorkGroupTaskBoardsAsync = createAsyncThunk("project/fetchWorkGroupTaskBoards", async (partyId: EntityId, { getState }) => {
  const result = await fetchWorkGroupTaskBoards(partyId);
  return { taskBoards: result, partyId: partyId };
});

export const fetchProjectWorkGroupBranchFileTreeAsync = createAsyncThunk(
  "project/fetchProjectWorkGroupBranchFileTreeAsync",
  async (dto: { projectId: EntityId; partyId: EntityId; branch: FileEntityType }, { getState }) => {
    const state: RootState = getState() as RootState;
    const fileIds = state.project.workGroups.selectedFileIds.map((f: { id: EntityId; branch: FileEntityBranch }) => f.id);
    return fetchProjectWorkGroupSelectedFileTree(dto.projectId, dto.partyId, dto.branch, fileIds);
  }
);

export const fetchProjectWorkGroupPrivilegesAsync = createAsyncThunk("project/fetchCurrentWorkGroupPrivileges", async (partyId: EntityId, { getState }) => {
  return fetchProjectWorkGroupPrivileges(partyId);
});

export const saveWorkGroupUserAuthorityChangeAsync = createAsyncThunk("project/saveWorkGroupUserAuthorityChange", async (dto: IPartyPrivilegeChangeDTO, { dispatch }) => {
  const result = await saveWorkGroupUserAuthorityChange(dto);
  dispatch(userAuthChanged(result));
});

export const saveWorkGroupFileSelectedAsync = createAsyncThunk("project/saveWorkGroupFileSelect", async (dto: { file: IFileTreeDTO; workGroupId: EntityId }, { dispatch }) => {
  await saveWorkGroupFileSelected(dto.file, dto.workGroupId);
  dispatch(fileAddedToWorkGroup({ file: dto.file, workGroupId: dto.workGroupId }));
});

export const saveWorkGroupUserInviteAsync = createAsyncThunk("project/saveWorkGroupUserInvite", async (dto: { workGroupId: EntityId; dto: IPartyPrivilegeListItemDTO }, { dispatch }) => {
  if (dto.dto?.username) {
    dto.dto.username = dto.dto.username.toLowerCase().replace(/[\s\u200B-\u200D\uFEFF]/g, "");
    const result = await saveWorkGroupUserInvite(dto.workGroupId, dto.dto);
    dispatch(workGroupUserInviteAdded(result));
  }
});

export const saveNewPartyAsync = createAsyncThunk("project/saveNewParty", async (dto: IPartyDTO, { dispatch }) => {
  const result = await saveNewParty(dto);
  dispatch(workGroupAdded(result));
});

export const saveRemoveUserFromWorkGroupAsync = createAsyncThunk("project/saveRemoveUserFromWorkGroupAsync", async (dto: { workGroupId: EntityId; dto: IPartyPrivilegeListItemDTO }, { dispatch }) => {
  const result = await saveRemoveUserFromWorkGroup(dto.workGroupId, dto.dto);
  dispatch(workGroupUserInviteRemoved(result));
});

export const saveWorkGroupFileDeSelectedAsync = createAsyncThunk("project/saveWorkGroupFileDeSelect", async (dto: { file: IFileTreeDTO; workGroupId: EntityId }, { dispatch }) => {
  await saveWorkGroupFileDeSelected(dto.file, dto.workGroupId);
  dispatch(fileRemovedFromWorkgroup({ file: dto.file, workGroupId: dto.workGroupId }));
});

export const saveAddUserToTaskBoardAsync = createAsyncThunk("project/saveAddUserToTaskBoardAsync", async (dto: IPartyUserTaskBoardChangeDTO, { dispatch }) => {
  const result = await saveAddUserToTaskBoard(dto);
  dispatch(userAddedToTaskboard(result));
});

export const saveAddWorkGroupToTaskBoardAsync = createAsyncThunk("project/saveAddWorkGroupToTaskBoardAsync", async (dto: { taskBoardId: EntityId; workGroupId: EntityId }, { dispatch }) => {
  const result = await saveAddWorkGroupToTaskBoard(dto);
  dispatch(taskBoardAddedToWorkGroup({ workGroupId: dto.workGroupId, taskBoard: result }));
});

export const saveRemoveWorkGroupFromTaskBoardAsync = createAsyncThunk("project/saveRemoveWorkGroupFromTaskBoardAsync", async (dto: { taskBoardId: EntityId; workGroupId: EntityId }, { dispatch }) => {
  const result = await saveRemoveWorkGroupFromTaskBoard(dto);
  dispatch(taskBoardRemovedFromWorkGroup({ workGroupId: dto.workGroupId, taskBoard: result }));
});

export const saveAddAllUsersToTaskBoardAsync = createAsyncThunk("project/saveAddAllUsersToTaskBoardAsync", async (dto: IPartyUserTaskBoardChangeDTO, { dispatch }) => {
  const result = await saveAddAllUsersToTaskBoard(dto);
  dispatch(allUsersAddedToTaskboard(result));
});

export const saveRemoveAllUsersFromTaskBoardAsync = createAsyncThunk("project/saveRemoveAllUsersFromTaskBoardAsync", async (dto: IPartyUserTaskBoardChangeDTO, { dispatch }) => {
  const result = await saveRemoveAllUsersFromTaskBoard(dto);
  dispatch(allUsersRemovedFromTaskboard(result));
});

export const saveRemoveUserFromTaskBoardAsync = createAsyncThunk("project/saveRemoveUserFromTaskBoardAsync", async (dto: IPartyUserTaskBoardChangeDTO, { dispatch }) => {
  const result = await saveRemoveUserFromTaskBoard(dto);
  dispatch(userRemovedFromTaskboard(result));
});

export const saveRenameWorkGroupAsync = createAsyncThunk("project/saveRenameWorkGroupAsync", async (dto: { name: string; workGroupId: EntityId }, { dispatch }) => {
  const result = await saveRenameWorkGroup(dto.name, dto.workGroupId);
  dispatch(workGroupRenamed({ name: result.value, workGroupId: dto.workGroupId }));
});

export const saveChangeWorkGroupTypeAsync = createAsyncThunk("project/saveChangeWorkGroupTypeAsync", async (dto: { type: WorkGroupType | null; workGroupId: EntityId }, { dispatch }) => {
  const result = await saveChangeWorkGroupType(dto.type, dto.workGroupId);
  dispatch(workGroupTypeChanged({ type: result.value, workGroupId: dto.workGroupId }));
});

export const deleteWorkGroupAsync = createAsyncThunk("project/deleteWorkGroup", async (dto: { projectId: EntityId; workGroupId: EntityId }) => {
  return deleteWorkGroup(dto.projectId, dto.workGroupId);
});

export const saveMaruWorkGroupClientChange = createAsyncThunk("project/saveMaruWorkGroupClientChange", async (dto: { name: string; uniqueClientId: string; workGroupId: EntityId }, { dispatch }) => {
  const result = await saveRenameWorkGroup(dto.name, dto.workGroupId, dto.uniqueClientId);
  dispatch(maruClientSelected({ name: result.value, uniqueClientId: dto.uniqueClientId, workGroupId: dto.workGroupId }));
});

export const saveMaruWorkGroupValuationAsync = createAsyncThunk("project/saveMaruWorkGroupValuation", async (dto: { workGroupId: EntityId; maruValuation: IMaruValuation }) => {
  return saveMaruValuation(dto.workGroupId, dto.maruValuation);
});

export const setWorkGroupFilter = createAction<IWorkGroupFilter>("project/workGroup/setWorkGroupFilter");
export const toggleWorkGroupTypeFilter = createAction<IWorkGroupFilterTypeDropdown>("project/workGroup/setWorkGroupFilterType");
export const clearWorkGroupTypeFilter = createAction("project/workGroup/clearWorkGroupFilterType");
export const partiesSortSet = createAction<IPartiesSort>("project/workGroup/partiesSortSet");
export const setAddUserModalShown = createAction<boolean>("project/workGroup/user/modal/shown");
export const setRemoveUserModalShown = createAction<boolean>("project/workGroup/user/removeModal/shown");
export const workGroupUserInviteAdded = createAction<IPartyPrivilegeListItemDTO>("project/workGroup/addWorkGroupUserInvite");
export const workGroupUserInviteRemoved = createAction<IPartyPrivilegeListItemDTO>("project/workGroup/removeWorkGroupUserInvite");
export const workGroupAdded = createAction<IWorkGroup>("project/workGroup/workGroupAdded");
export const userAuthChanged = createAction<IPartyPrivilegeChangeDTO>("project/workGroup/userAuthChanged");
export const fileAddedToWorkGroup = createAction<{ file: IFileTreeDTO; workGroupId: EntityId }>("project/workGroup/fileAddedToWg");
export const fileRemovedFromWorkgroup = createAction<{ file: IFileTreeDTO; workGroupId: EntityId }>("project/workGroup/fileRemovedFromWg");
export const userAddedToTaskboard = createAction<IPartyUserTaskBoardChangeDTO>("project/workGroup/userAddedToTaskboard");
export const userRemovedFromTaskboard = createAction<IPartyUserTaskBoardChangeDTO>("project/workGroup/userRemovedFromTaskboard");
export const allUsersAddedToTaskboard = createAction<IPartyUserTaskBoardChangeDTO>("project/workGroup/allUsersAddedToTaskboard");
export const allUsersRemovedFromTaskboard = createAction<IPartyUserTaskBoardChangeDTO>("project/workGroup/allUserRemovedFromTaskboard");
export const workGroupRenamed = createAction<{ name: string; workGroupId: EntityId }>("project/workGroup/workGroupRenamed");
export const workGroupTypeChanged = createAction<{ type: string | null; workGroupId: EntityId }>("project/workGroup/workGroupTypeChanged");
export const projectWorkGroupStateReset = createAction<void>("project/workGroup/projectWorkGroupStateReset");
export const taskBoardAddedToWorkGroup = createAction<{ workGroupId: EntityId; taskBoard: ITaskBoard }>("project/workGroup/taskBoardAddedToWorkGroup");
export const taskBoardRemovedFromWorkGroup = createAction<{ workGroupId: EntityId; taskBoard: ITaskBoard }>("project/workGroup/taskBoardRemovedFromWorkGroup");
export const maruClientSelected = createAction<{ name: string; uniqueClientId: string; workGroupId: EntityId }>("project/maru/client/selected");

export const projectWorkGroupExtraReducer = createReducer(projectWorkGroupInitialState, (builder) => {
  builder
    .addCase(fetchProjectWorkGroupsAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(fetchProjectWorkGroupsAsync.fulfilled, (state, action) => {
      state.status = BhStateStatusType.SUCCESS;
      projectWorkGroupAdapter.setAll(state, action.payload);
    })
    .addCase(fetchWorkGroupAsync.fulfilled, (state, action) => {
      projectWorkGroupAdapter.upsertOne(state, action.payload);
      state.selectedFileIds = [];
      action.payload.files.forEach((file) => {
        state.selectedFileIds.push({ id: file.id, branch: FileEntityBranch.ROOT_DIR });
      });
      action.payload.documents.forEach((file) => {
        state.selectedFileIds.push({ id: file.id, branch: FileEntityBranch.ROOT_DOCUMENT_DIR });
      });
    })
    .addCase(fetchWorkGroupTaskBoardsAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(fetchWorkGroupTaskBoardsAsync.fulfilled, (state, action) => {
      state.status = BhStateStatusType.SUCCESS;
      const { taskBoards, partyId } = action.payload;
      const existingWorkGroup = state.entities[partyId];
      if (existingWorkGroup) existingWorkGroup.taskBoards = taskBoards;
    })
    .addCase(fetchProjectWorkGroupPrivilegesAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(fetchProjectWorkGroupPrivilegesAsync.fulfilled, (state, action) => {
      state.currentUsersAndInvites = action.payload;
    })
    .addCase(fetchProjectWorkGroupBranchFileTreeAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(fetchProjectWorkGroupBranchFileTreeAsync.fulfilled, (state, action) => {
      const fileIds = state.selectedFileIds.map((f: { id: EntityId; branch: FileEntityBranch }) => f.id);
      state.currentFileTree = createFileTree(action.payload, fileIds);
    })
    .addCase(saveWorkGroupUserAuthorityChangeAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(saveWorkGroupUserAuthorityChangeAsync.fulfilled, (state, action) => {
      state.status = BhStateStatusType.SUCCESS;
    })
    .addCase(saveRemoveUserFromWorkGroupAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(saveRemoveUserFromWorkGroupAsync.fulfilled, (state, action) => {
      state.status = BhStateStatusType.SUCCESS;
      state.modals.removeUserModalShown = false;
    })
    .addCase(workGroupUserInviteRemoved, (state, action) => {
      const user = state.currentUsersAndInvites.find((listItem) => {
        return (
          (action.payload.userEntityId && listItem.userEntityId === action.payload.userEntityId) ||
          (action.payload.inviteId && listItem.inviteId === action.payload.inviteId) ||
          (action.payload.username && listItem.username === action.payload.username)
        );
      });
      if (user) {
        state.currentUsersAndInvites = state.currentUsersAndInvites.filter((listItem) => {
          return (
            (action.payload.userEntityId && listItem.userEntityId !== action.payload.userEntityId) ||
            (action.payload.inviteId && listItem.inviteId !== action.payload.inviteId) ||
            (action.payload.username && listItem.username !== action.payload.username)
          );
        });
      }
    })
    .addCase(saveRenameWorkGroupAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(saveRenameWorkGroupAsync.fulfilled, (state) => {
      state.status = BhStateStatusType.SUCCESS;
    })
    .addCase(workGroupRenamed, (state, action) => {
      const { name, workGroupId } = action.payload;
      const existingWorkGroup = state.entities[workGroupId];
      if (existingWorkGroup) existingWorkGroup.name = name;
    })
    .addCase(saveChangeWorkGroupTypeAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(saveChangeWorkGroupTypeAsync.fulfilled, (state, action) => {
      state.status = BhStateStatusType.SUCCESS;
    })
    .addCase(workGroupTypeChanged, (state, action) => {
      const { type, workGroupId } = action.payload;
      const existingWorkGroup = state.entities[workGroupId];
      if (existingWorkGroup) existingWorkGroup.type = type as WorkGroupType;
    })
    // TASKBOARD
    .addCase(saveAddUserToTaskBoardAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(saveAddUserToTaskBoardAsync.fulfilled, (state) => {
      state.status = BhStateStatusType.SUCCESS;
    })
    .addCase(userAddedToTaskboard, (state, action) => {
      const user = state.currentUsersAndInvites.find((user) => {
        return (
          (action.payload.userEntityId && user.userEntityId === action.payload.userEntityId) ||
          (action.payload.inviteId && user.inviteId === action.payload.inviteId) ||
          (action.payload.username && user.username === action.payload.username)
        );
      });
      if (user && user.taskBoardIds.indexOf(action.payload.taskBoardId) === -1) {
        user.taskBoardIds = [...user.taskBoardIds, action.payload.taskBoardId];
      }
    })
    .addCase(taskBoardAddedToWorkGroup, (state, action) => {
      const existingWorkGroup = state.entities[action.payload.workGroupId];
      if (existingWorkGroup) existingWorkGroup.taskBoards.push(action.payload.taskBoard);
      state.currentUsersAndInvites.forEach((user) => {
        user.taskBoardIds = [...user.taskBoardIds, action.payload.taskBoard.id];
      });
    })
    .addCase(taskBoardRemovedFromWorkGroup, (state, action) => {
      const existingWorkGroup = state.entities[action.payload.workGroupId];
      if (existingWorkGroup) {
        existingWorkGroup.taskBoards = existingWorkGroup.taskBoards.filter((taskBoard) => taskBoard.id !== action.payload.taskBoard.id);
      }
      state.currentUsersAndInvites.forEach((user) => {
        user.taskBoardIds = user.taskBoardIds.filter((taskBoardId) => taskBoardId !== action.payload.taskBoard.id);
      });
    })
    .addCase(saveAddAllUsersToTaskBoardAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(saveAddAllUsersToTaskBoardAsync.fulfilled, (state) => {
      state.status = BhStateStatusType.SUCCESS;
    })
    .addCase(allUsersAddedToTaskboard, (state, action) => {
      state.currentUsersAndInvites.forEach((user) => {
        user.taskBoardIds.push(action.payload.taskBoardId);
      });
    })
    .addCase(saveRemoveAllUsersFromTaskBoardAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(saveRemoveAllUsersFromTaskBoardAsync.fulfilled, (state) => {
      state.status = BhStateStatusType.SUCCESS;
    })
    .addCase(allUsersRemovedFromTaskboard, (state, action) => {
      state.currentUsersAndInvites.forEach((user) => {
        user.taskBoardIds = user.taskBoardIds.filter((tbId) => tbId !== action.payload.taskBoardId);
      });
    })
    .addCase(saveRemoveUserFromTaskBoardAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(saveRemoveUserFromTaskBoardAsync.fulfilled, (state) => {
      state.status = BhStateStatusType.SUCCESS;
    })
    .addCase(userRemovedFromTaskboard, (state, action: PayloadAction<IPartyUserTaskBoardChangeDTO>) => {
      const user = state.currentUsersAndInvites.find((user) => {
        return user.userEntityId === action.payload.userEntityId;
      });
      if (user) {
        user.taskBoardIds = user.taskBoardIds.filter((tbId) => tbId !== action.payload.taskBoardId);
      }
    })
    .addCase(setWorkGroupFilter, (state, action: PayloadAction<IWorkGroupFilter>) => {
      state.filter = action.payload;
    })
    .addCase(toggleWorkGroupTypeFilter, (state, action: PayloadAction<IWorkGroupFilterTypeDropdown>) => {
      const toggledFilter = state.filter.typeDropdownValues.find((value) => value.type === action.payload.type);
      toggledFilter && (toggledFilter.selected = !toggledFilter.selected);
    })
    .addCase(clearWorkGroupTypeFilter, (state) => {
      state.filter.typeDropdownValues.forEach((type) => (type.selected = false));
    })
    .addCase(partiesSortSet, (state, action: PayloadAction<IPartiesSort>) => {
      state.sort = action.payload;
    })
    .addCase(setAddUserModalShown, (state, action) => {
      state.modals.addUserModalShown = action.payload;
    })
    .addCase(workGroupUserInviteAdded, (state, action) => {
      if (action.payload.username && action.payload.username.length > 5) {
        const partyPrivilegeListItem = { ...action.payload, username: action.payload.username.toLowerCase() };
        state.currentUsersAndInvites = [...state.currentUsersAndInvites, partyPrivilegeListItem];
        state.modals.addUserModalShown = false;
      }
    })
    .addCase(setRemoveUserModalShown, (state, action) => {
      state.modals.removeUserModalShown = action.payload;
    })
    .addCase(workGroupAdded, (state, action) => {
      projectWorkGroupAdapter.upsertOne(state, action.payload);
    })
    .addCase(userAuthChanged, (state, action) => {
      const users = state.currentUsersAndInvites.map((user: IPartyPrivilegeListItemDTO) => {
        return { ...user };
      });
      users.forEach((user) => {
        const isSelectedUserEntity = action.payload.userEntityId && user.userEntityId === action.payload.userEntityId;
        const isUserToBeInvited = action.payload.uniqueId && user.uniqueId === action.payload.uniqueId;
        const isInvitedUser = action.payload.inviteId && user.inviteId === action.payload.inviteId;
        if (isSelectedUserEntity || isUserToBeInvited || isInvitedUser) {
          if (action.payload.subResource === SubResource.DOCUMENT) {
            user.documents = action.payload.authLevel;
          }
          if (action.payload.subResource === SubResource.FILE) {
            user.files = action.payload.authLevel;
          }
          if (action.payload.subResource === SubResource.MODEL) {
            user.models = action.payload.authLevel;
          }
          if (action.payload.subResource === SubResource.ACT) {
            user.acts = action.payload.authLevel;
          }
          if (action.payload.subResource === SubResource.CONTRACT) {
            user.contracts = action.payload.authLevel;
          }
        }
      });
      state.currentUsersAndInvites = users;
    })
    .addCase(fileAddedToWorkGroup, (state, action) => {
      const file = action.payload.file;
      if (file) {
        file.selected = true;
        state.selectedFileIds = [...state.selectedFileIds, { id: file.id, branch: file.branch }];
      }
    })
    .addCase(fileRemovedFromWorkgroup, (state, action) => {
      const file = action.payload.file;
      if (file) {
        file.selected = false;
        state.selectedFileIds = state.selectedFileIds.filter((fileId) => fileId.id !== file.id);
      }
    })
    .addCase(projectWorkGroupStateReset, (state) => projectWorkGroupInitialState)
    .addCase(deleteWorkGroupAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(deleteWorkGroupAsync.fulfilled, (state, action) => {
      projectWorkGroupAdapter.removeOne(state, action.payload.id);
    })
    .addCase(maruClientSelected, (state, action) => {
      const { name, uniqueClientId, workGroupId } = action.payload;
      const existingWorkGroup = state.entities[workGroupId];
      if (existingWorkGroup) {
        existingWorkGroup.name = name;
        existingWorkGroup.uniqueClientId = uniqueClientId;
      }
    })
    .addCase(saveMaruWorkGroupValuationAsync.pending, (state) => {
      state.status = BhStateStatusType.PENDING;
    })
    .addCase(saveMaruWorkGroupValuationAsync.fulfilled, (state, action) => {
      state.status = BhStateStatusType.SUCCESS;
      const workGroup = Object.values(state.entities).find((wg) => wg && wg.uniqueClientId === action.payload.uniqueclientid);
      if (workGroup) {
        workGroup.maruValuationId = action.payload.id;
      }
    });
});

export const {
  selectAll: selectAllProjectWorkGroups,
  selectIds: selectAllProjectWorkGroupIds,
  selectById: selectProjectWorkGroupById
} = projectWorkGroupAdapter.getSelectors((state: RootState) => state.project.workGroups);

export const selectIsAddUserModalShown = (state: RootState) => state.project.workGroups.modals.addUserModalShown;
export const selectIsRemoveUserModalShown = (state: RootState) => state.project.workGroups.modals.removeUserModalShown;
export const selectCurrentWorkGroupFileTree = (state: RootState) => state.project.workGroups.currentFileTree;
export const selectCurrentWorkGroupUsersInvites = (state: RootState) => state.project.workGroups.currentUsersAndInvites;
export const selectCurrentProjectWorkGroupsFilter = (state: RootState) => state.project.workGroups.filter;
export const selectCurrentProjectWorkGroupSort = (state: RootState) => state.project.workGroups.sort;
export const selectSelectedFileIds = (state: RootState) => state.project.workGroups.selectedFileIds;
export const selectProjectWorkGroupsCount = (state: RootState) => state.project.workGroups.ids.length;

export const selectCurrentWorkGroupUsersInvitesSorted = createSelector([selectCurrentWorkGroupUsersInvites], (usersInvites: IPartyPrivilegeListItemDTO[]) => {
  const users = usersInvites.filter((u) => u.userEntityId);
  const invites = usersInvites.filter((u) => !u.userEntityId || u.userEntityId === "");
  const usersSorted = [...users].sort((a, b) => sortByFullNameAndEmail(a, b));
  const invitesSorted = [...invites].sort((a, b) => naturalSortByField(a, b, "username"));
  return [...usersSorted, ...invitesSorted];
});

export const selectAllProjectWorkGroupsSorted = createSelector([selectAllProjectWorkGroups, selectCurrentProjectWorkGroupSort], (workGroups, sort) => {
  return workGroups.sort((a: IWorkGroup, b: IWorkGroup) => naturalSortByField(a, b, sort.property, sort.reversed));
});

export const selectProjectWorkGroupIdsFiltered = createSelector(
  [selectAllProjectWorkGroups, selectCurrentProjectWorkGroupsFilter, selectCurrentProjectWorkGroupSort],
  (workGroups, filter: IWorkGroupFilter, sort: IPartiesSort) => {
    const sorted = workGroups.filter((wg: IWorkGroup) => wg.id !== -1).sort((a: IWorkGroup, b: IWorkGroup) => naturalSortByField(a, b, sort.property, sort.reversed));
    return sorted
      .filter((workGroup: IWorkGroup) => {
        return workGroup.name.toLowerCase().includes(filter.name.toLowerCase());
      })
      .filter((workGroup: IWorkGroup) => {
        const typeFilterSelected = filter.typeDropdownValues
          .filter((value) => value.selected)
          .map((value) => {
            return value.type;
          });
        return typeFilterSelected.length === 0 ? workGroup : typeFilterSelected.includes(workGroup.type);
      })
      .map((workGroup: IWorkGroup) => workGroup.id);
  }
);
