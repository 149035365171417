import React, { FC, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import { allFileEntitiesUnSelected, resetFileDirectoryModalState, selectAuthoritiesModalFileEntityId } from "@/app/store/filesSlice";
import BhModalFooter from "@components/modal/BhModalFooter";
import AuthoritiesModalHeader from "@/views/home/project/detail/directory/directoryModals/authoritiesModal/AuthoritiesModalHeader";
import BhTabs from "@components/tabs/BhTabs";
import AuthoritiesModalPartiesTab from "@/views/home/project/detail/directory/directoryModals/authoritiesModal/AuthoritiesModalPartiesTab";
import AuthoritiesModalAdministratorsTab from "@/views/home/project/detail/directory/directoryModals/authoritiesModal/AuthoritiesModalAdministratorsTab";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectBimDirectoryId, selectCurrentProjectId, selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";
import { fetchFileAccessList } from "@/api/fileAPI";
import { IFileAccess } from "@/model/files/IFileAccess";
import { useTranslation } from "react-i18next";

const AuthoritiesModal: FC = () => {
  const { t } = useTranslation();
  const [directoryAccess, setDirectoryAccess] = useState<IFileAccess>({} as IFileAccess);
  const projectId = useAppSelector(selectCurrentProjectId);
  const fileId = useAppSelector(selectAuthoritiesModalFileEntityId);
  const rootDirId = useAppSelector(selectRootDirectoryId);
  const rootDocumentDirId = useAppSelector(selectRootDocumentDirectoryId);
  const bimDirId = useAppSelector(selectBimDirectoryId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fileId && fetchFileAccessList(projectId, fileId).then((res) => setDirectoryAccess(res));

    return function cleanup() {
      setDirectoryAccess({} as IFileAccess);
    };
  }, []);

  if (!fileId) return null;

  const handleClose = () => {
    dispatch(resetFileDirectoryModalState());
    if ([rootDirId, rootDocumentDirId, bimDirId].includes(fileId)) {
      dispatch(allFileEntitiesUnSelected());
    }
  };

  const tabs = [
    {
      id: 0,
      header: `${t("GLOBAL.WORKGROUPS")}`,
      content: <AuthoritiesModalPartiesTab disableAddAuthorities={fileId === bimDirId} directoryAccess={directoryAccess} setDirectoryAccess={setDirectoryAccess} />
    },
    { id: 1, header: `${t("GLOBAL.USERS.ADMINS")}`, content: <AuthoritiesModalAdministratorsTab administrators={directoryAccess.projectAdmins} /> }
  ];

  return (
    <BhModal
      isShown={true}
      setIsShown={handleClose}
      header={<AuthoritiesModalHeader fileId={fileId} />}
      footer={<BhModalFooter cancelButtonText={`${t("GLOBAL.CLOSE")}`} onCancel={handleClose} />}
      size="5xl"
    >
      <div className="h-full px-7 pt-3">
        <BhTabs tabs={tabs} initialActiveTab={0} />
      </div>
    </BhModal>
  );
};

export default AuthoritiesModal;
