import React, { FC, useEffect, useRef, useState } from "react";
import { CategoryDefaultCode, IProjectTaskCategory } from "@/model/taskBoard/IProjectTaskCategory";
import { useTranslation } from "react-i18next";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  category: IProjectTaskCategory;
}

const TaskCategoryTag: FC<Props> = ({ category }) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [roundedClass, setRoundedClass] = useState("rounded-full");

  useEffect(() => {
    if (containerRef.current) {
      setRoundedClass(containerRef.current.clientHeight > 20 ? "rounded-lg" : "rounded-full");
    }
  }, [containerRef.current?.clientHeight, category]);

  if (!category || !category.name) return null;

  const translateValue = Object.keys(CategoryDefaultCode).includes(category?.name.toUpperCase());

  return (
    <div ref={containerRef} className={classNames(roundedClass, "flex w-fit max-w-full flex-row items-center px-1.5")} style={{ backgroundColor: category?.color }}>
      <span className="text-12px bh-text-white max-w-full break-words font-bold">{translateValue ? t("TASK.CATEGORY." + category?.name.toUpperCase()) : category?.name}</span>
    </div>
  );
};

export default TaskCategoryTag;
