import React, { FC, useCallback, useEffect, useState } from "react";
import PartyUserListItemDropdown from "@/views/home/project/detail/users/parties/party/PartyUserListItemDropdown";
import { SubResource } from "@/model/IUserAuthority";
import { IPartyPrivilegeListItemDTO } from "@/model/party/IPartyPrivilegeListItemDTO";
import BhDropdownButton from "@components/dropdown/BhDropdownButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { IRoleAuthName, ROLES } from "@/model/party/IRoleAuthName";
import { fetchRelatedUsersInProjectWithSearch } from "@/api/projectAPI";
import { convertSimpleValidUserAuthorityToUser } from "@/model/ISimpleValidUserAuthority";
import { IUser } from "@/model/IUser";
import BhComboboxStackedLabel from "@components/input/BhComboboxStackedLabel";
import { IWorkGroup } from "@/model/IWorkGroup";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import { selectBimProjectModule } from "@/app/store/project/projectSlice";
import { useAppSelector } from "@/app/hooks";

interface Props {
  workGroup: IWorkGroup;
  userToAdd: IPartyPrivilegeListItemDTO;
  setUserToAdd: Function;
  usersToSuggest: Array<IUser>;
  setUsersToSuggest: Function;
  usersInvites?: Array<IPartyPrivilegeListItemDTO>;
  isModal?: boolean;
}

const PartyAddUserContentContainer: FC<Props> = ({ workGroup, userToAdd, setUserToAdd, usersToSuggest, setUsersToSuggest, usersInvites, isModal }) => {
  const { t } = useTranslation();
  const [queryValue, setQueryValue] = useState("");
  const isBimProject = useAppSelector(selectBimProjectModule);

  useEffect(() => {
    workGroup?.projectId &&
      fetchRelatedUsersInProjectWithSearch(workGroup.projectId).then((users) => {
        return setUsersToSuggest(users.map((simpleValidUser) => convertSimpleValidUserAuthorityToUser(simpleValidUser)));
      });
  }, []);

  useEffect(() => {
    if (!userToAdd.username) {
      setQueryValue("");
    }
  }, [userToAdd]);

  const callback = useCallback(
    (authLevelDropdownValue: any, subResource: SubResource, fieldName: keyof IPartyPrivilegeListItemDTO) => {
      const value = { [fieldName]: authLevelDropdownValue.value } as IPartyPrivilegeListItemDTO;
      setUserField(value);
    },
    [userToAdd]
  );

  const setUserField = useCallback(
    (value: IPartyPrivilegeListItemDTO) => {
      setUserToAdd((previousUserToAdd: IPartyPrivilegeListItemDTO) => {
        return { ...previousUserToAdd, ...value };
      });
    },
    [userToAdd]
  );

  const setInputQueryValueCallback = useCallback(
    (value: string) => {
      setQueryValue(value);
      if (value || value === "") {
        const usernameWithoutSpaces = value.toLowerCase().replace(/\s+/g, "");
        setUserToAdd((previousUserToAdd: IPartyPrivilegeListItemDTO) => {
          return { ...previousUserToAdd, ...({ username: usernameWithoutSpaces } as IPartyPrivilegeListItemDTO) };
        });
      }
    },
    [queryValue]
  );

  const getSelectedRoleName = useCallback(() => {
    const role = ROLES.find((role) => {
      return role.documents === userToAdd.documents && role.files === userToAdd.files;
    }) || { name: "ROLE.CUSTOM" };
    return t(role.name);
  }, [userToAdd]);

  const selectUser = useCallback((user: IUser) => {
    if (user && user.username) {
      setUserToAdd((previousUserToAdd: IPartyPrivilegeListItemDTO) => {
        return {
          ...previousUserToAdd,
          ...({ username: user.username, firstName: user.firstName, lastName: user.lastName, userEntityId: user.id, uniqueId: "user-" + user.id } as IPartyPrivilegeListItemDTO)
        };
      });
    }
  }, []);

  return (
    <div className={classNames("w-full", isModal && "px-20 pb-40 pt-12")}>
      <div className="w-full">
        <BhComboboxStackedLabel
          initialValue={userToAdd.username}
          label={t("WORKGROUP.NEW.USERS.EMAIL")}
          property={"selectedUser"}
          values={usersToSuggest}
          placeholder={t("WORKGROUP.NEW.USERS.EMAIL.PLACEHOLDER") as string}
          customType={BhDropdownTypeEnum.USER}
          onSelect={(val: any) => selectUser(val.selectedUser)}
          queryValueCallback={setInputQueryValueCallback}
          keepValueVisibleAfterSelect={true}
          visibleValueProperty={"username"}
          showValueCondition={(dto: IPartyPrivilegeListItemDTO) => {
            const exists = usersInvites?.find((ui) => ui.username === dto.username);
            return !exists;
          }}
          showUsernameWithUserValues={true}
        />
      </div>
      <p className="mt-6 font-bold">{t("WORKGROUP.NEW.USERS.AUTHORITIES")}</p>
      <div className="bh-bg-smoke-50 bh-border-white flex w-full flex-row flex-wrap overflow-visible rounded border">
        <div className="flex flex-1 flex-col p-4">
          <div className="px-2">{t("SHARE.HEADER.RIGHTS")}</div>
          <div>
            <BhDropdown
              fixedPosition={true}
              button={<BhDropdownButton reversed={true} title="" value={getSelectedRoleName()} />}
              menu={
                <BhDropdownMenu
                  values={ROLES}
                  textProperty="name"
                  type={BhDropdownTypeEnum.STRING}
                  onSelect={(role: IRoleAuthName) => {
                    setUserField({ documents: role.documents, files: role.files, models: role.models } as IPartyPrivilegeListItemDTO);
                  }}
                  translateValues={true}
                />
              }
            />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="bh-bg-smoke flex flex-col p-4">
            <div className="px-2">{t("GLOBAL.DRAWINGS")}</div>
            <div>
              <PartyUserListItemDropdown workGroup={workGroup} listItem={userToAdd} fieldName="files" subResource={SubResource.FILE} callback={callback} />
            </div>
          </div>
          <div className="bh-bg-smoke flex flex-col p-4">
            <div className="px-2">{t("GLOBAL.DOCUMENTS")}</div>
            <div>
              <PartyUserListItemDropdown workGroup={workGroup} listItem={userToAdd} fieldName="documents" subResource={SubResource.DOCUMENT} callback={callback} />
            </div>
          </div>
          {isBimProject && (
            <div className="bh-bg-smoke flex flex-col p-4">
              <div className="px-2">{t("BIM.FILES")}</div>
              <div>
                <PartyUserListItemDropdown workGroup={workGroup} listItem={userToAdd} fieldName="models" subResource={SubResource.MODEL} callback={callback} />
              </div>
            </div>
          )}
          <div className="bh-bg-smoke flex flex-col p-4">
            <div className="px-2">{t("PARTY.ACTS")}</div>
            <div>
              <PartyUserListItemDropdown workGroup={workGroup} listItem={userToAdd} fieldName="acts" subResource={SubResource.ACT} callback={callback} />
            </div>
          </div>
          <div className="bh-bg-smoke flex flex-col p-4">
            <div className="px-2">{t("PARTY.CONTRACTS")}</div>
            <div>
              <PartyUserListItemDropdown workGroup={workGroup} listItem={userToAdd} fieldName="contracts" subResource={SubResource.CONTRACT} callback={callback} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartyAddUserContentContainer;
