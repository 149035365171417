import React, { FC } from "react";
import { IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/form/formSlice";
import BhDatepickerStackedLabel from "@components/input/BhDatepickerStackedLabel";
import { useTranslation } from "react-i18next";

interface Props {
  field: IFormBaseVersionRowField;
  saveCallback?: Function;
  disabled?: boolean;
  isPreview?: boolean;
}

const FormDateField: FC<Props> = ({ field, saveCallback, disabled, isPreview }) => {
  const { t } = useTranslation();
  const inputValue = useAppSelector((state) => (!isPreview ? selectValueByPropertyFromCurrentFormData(state, field.property) : field.value.date));
  const isPrefillInput = field.value.prefill && field.value.prefill.enabled;

  return (
    <div className="mb-1">
      <BhDatepickerStackedLabel
        property={field.property}
        disabled={disabled}
        label={field.value.label || "\u00A0"}
        onChangeCallback={saveCallback}
        placeholder={isPrefillInput && isPreview ? (t("FORMBUILDER.FORM.BASE.FIELD.DATE_PLACEHOLDER") as string) : "\u00A0"}
        initialValue={inputValue ? new Date(inputValue) : undefined}
        widthClasses={"!min-w-full"}
        wrapperClasses={"w-full"}
        iconVisible={true}
      />
    </div>
  );
};

export default FormDateField;
