import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import {
  fetchFieldDataOfSameTypeFormsForFormFieldDateRangeAsync,
  resetFormModalsData,
  selectCurrentForm,
  selectImportFieldInfoModalData,
  selectImportFieldInfoModalField,
  selectImportFieldInfoModalOpen,
  selectIsAttachmentsField,
  setFormModalsData,
  toggleFormModalsAttachmentField,
  toggleFormModalsOpen
} from "@/app/store/form/formSlice";
import { selectCurrentProjectId, selectRootDirectoryId, selectRootDocumentDirectoryId } from "@/app/store/project/projectSlice";
import { IFormFieldImportDTO, IFormImportInfo } from "@/model/IForm";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import BhTableHeader from "@components/table/BhTableHeader";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import { EntityId } from "@reduxjs/toolkit";
import BhDatePicker from "@components/input/BhDatePicker";
import { formatDate, formatDateTimeInternationalFormat } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import DirectorySelectionModal from "@/views/home/project/detail/directory/directoryModals/DirectorySelectionModal";
import { IFileEntity, IFolderFileEntity } from "@/model/files/IFileEntity";
import BhFilterButton from "@components/filters/BhFilterButton";
import { naturalSortByField } from "@/utilities/sortUtilities";
import BhLoadingIconContained from "@components/loading/BhLoadingIconContained";
import { removeDuplicates } from "@/utilities/jsUtilities";

interface Props {
  dirId: EntityId;
  saveCallback?: Function;
}

const ImportFieldInfoModal: FC<Props> = ({ dirId, saveCallback }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const importFieldInfoModalOpen = useAppSelector(selectImportFieldInfoModalOpen);
  const importData = useAppSelector(selectImportFieldInfoModalData);
  const isAttachmentsField = useAppSelector(selectIsAttachmentsField);
  const projectId = useAppSelector(selectCurrentProjectId);
  const form = useAppSelector(selectCurrentForm);
  const field = useAppSelector(selectImportFieldInfoModalField);
  const date = new Date();
  const [since, setSince] = useState(new Date(date.setDate(date.getDate() - 7)));
  const [until, setUntil] = useState(new Date());
  const [directorySelectionModalOpen, setDirectorySelectionModalOpen] = useState(false);
  const fileRootDirectoryId = useAppSelector(selectRootDirectoryId);
  const documentRootDirectoryId = useAppSelector(selectRootDocumentDirectoryId);
  const [selectedFolderIds, setSelectedFolderIds] = useState([] as Array<EntityId>);
  const directorySelectionModalIds =
    selectedFolderIds && selectedFolderIds.length > 0 ? [...selectedFolderIds, fileRootDirectoryId, documentRootDirectoryId] : [fileRootDirectoryId, documentRootDirectoryId];
  const [importDataSorted, setImportedDataSorted] = useState([] as Array<IFormFieldImportDTO>);
  const [filter, setFilter] = useState({} as any);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFilter({ sortedBy: "formDate", asc: false });
  }, []);

  useEffect(() => {
    if (importFieldInfoModalOpen) {
      fetchModalData();
    }
  }, [importFieldInfoModalOpen, since, until, selectedFolderIds]);

  useEffect(() => {
    if (importData) {
      filterResults(importData);
    }
  }, [filter, importData]);

  if (!field) return null;

  const filterResults = (dataToSort: Array<IFormFieldImportDTO>) => {
    let sortedData = dataToSort.map((d) => d);
    if (filter.sortedBy === "formDate") {
      sortedData.sort((a, b) => (new Date(a.formDate) > new Date(b.formDate) ? (filter.asc ? 1 : -1) : filter.asc ? -1 : 1));
    } else {
      sortedData.sort((a, b) => naturalSortByField(a, b, filter.sortedBy, !filter.asc));
    }
    setImportedDataSorted(sortedData);
  };

  const fetchModalData = () => {
    setLoading(true);
    since.setHours(0, 0, 0, 0);
    until.setHours(23, 59, 59, 999);
    const formImportInfo = {
      projectId: projectId,
      directoryId: dirId,
      formId: form.id,
      formType: form.formType,
      field: field,
      isAttachmentsField: isAttachmentsField,
      dateField: "docDate",
      since: formatDateTimeInternationalFormat(since, false, true),
      until: formatDateTimeInternationalFormat(until, false, true),
      folderIds: selectedFolderIds && selectedFolderIds.length > 0 ? selectedFolderIds : []
    } as IFormImportInfo;
    dispatch(fetchFieldDataOfSameTypeFormsForFormFieldDateRangeAsync(formImportInfo)).then(() => {
      setLoading(false);
    });
  };

  const tableColumnHeaders: Array<IBhTableHeader<IFormFieldImportDTO>> = [
    { id: 1, content: t("IMPORT_FIELD_MODAL.TABLE_HEAD_COMPANY"), sortable: true, field: "companyName" },
    { id: 2, content: t("IMPORT_FIELD_MODAL.TABLE_HEAD_DATE"), sortable: true, field: "formDate" },
    { id: 3, content: t("IMPORT_FIELD_MODAL.TABLE_HEAD_FILENAME"), sortable: true, field: "formName" }
  ];

  const generateNewFieldData = (dataObject?: IFormFieldImportDTO) => {
    const dataArray = dataObject ? [dataObject] : importData;
    if (dataArray && !isAttachmentsField) {
      const fieldData = form.data[field] || "";
      let newData = fieldData;
      dataArray.forEach((data) => {
        if (newData) {
          newData += "\n";
        }
        const company = data.companyName ? data.companyName + " - " : "";
        newData += company + data.fieldData;
      });
      return { [field]: newData };
    }
    if (isAttachmentsField) {
      const documents = dataArray?.flatMap((data) => data.documents) || [];
      const uniqueDocuments = removeDuplicates(documents);
      const filteredDocuments = [...(form.data[field] || []), ...uniqueDocuments].filter((fileEntity) => {
        return !form.data[field]?.some((f: IFileEntity) => f.id === fileEntity.id);
      });
      return form.data[field] ? { [field]: [...form.data[field], ...filteredDocuments] } : { [field]: filteredDocuments };
    }
  };

  const importFieldDataToForm = (dataToImport?: IFormFieldImportDTO) => {
    if (importData) {
      let changedData;
      if (dataToImport) {
        changedData = importData.map((d) => {
          if (d.formId === dataToImport.formId) {
            return { ...d, ...{ added: true } };
          }
          return d;
        });
      } else {
        changedData = importData.map((data) => {
          return { ...data, ...{ added: true } };
        });
      }
      dispatch(setFormModalsData(changedData));

      let changedObject;
      if (dataToImport) {
        changedObject = generateNewFieldData(dataToImport);
      } else {
        changedObject = generateNewFieldData();
      }
      saveCallback && saveCallback(changedObject);
    }
  };

  const closeModal = () => {
    dispatch(toggleFormModalsOpen({ modal: "importFieldInfoModal" }));
    dispatch(toggleFormModalsAttachmentField(false));
    dispatch(resetFormModalsData());
  };

  const onFolderSelect = (selectedFolders: Array<IFolderFileEntity>) => {
    if (selectedFolders && selectedFolders.length > 0) {
      const folderIds = selectedFolders.map((f) => f.id);
      setSelectedFolderIds(folderIds);
    } else {
      setSelectedFolderIds([]);
    }
    setDirectorySelectionModalOpen(false);
  };

  const changeFilter = (header: IBhTableHeader<IFormFieldImportDTO>) => {
    if (filter.sortedBy === header.field) {
      setFilter({ sortedBy: header.field, asc: !filter.asc });
      return;
    }
    setFilter({ sortedBy: header.field, asc: filter.asc });
  };

  const showRow = (data: IFormFieldImportDTO) => {
    return isAttachmentsField ? data.documents.length > 0 : data.fieldData !== null;
  };

  return (
    <BhModal
      size="5xl"
      isShown={true}
      setIsShown={() => {}}
      onClose={closeModal}
      header={
        <div className="h-7 pl-4">
          <h2 className="mt-1 leading-7">{t("IMPORT_FIELD_MODAL.HEADER")}</h2>
        </div>
      }
      children={
        <BhScrollableBody>
          <div className="bh-border-pigeon-40 border-t px-20 pt-14 pb-40">
            <div className="text-14px l-h-18px bh-text-deep-ocean-80 mb-3 place-self-start font-semibold">{t("IMPORT_FIELD_MODAL.DATE_RANGE")}</div>
            <div className="mb-6 flex flex-row items-center">
              <div className="mr-3">
                <BhDatePicker property={"since"} initialValue={since} onChangeCallback={(value: any) => setSince(value.since)} />
              </div>
              <div className="mr-2">
                <BhDatePicker property={"until"} initialValue={until} onChangeCallback={(value: any) => setUntil(value.until)} />
              </div>
              <div>
                <BhFilterButton selectedValues={selectedFolderIds ? selectedFolderIds.length : 0} onReset={onFolderSelect} onClick={() => setDirectorySelectionModalOpen(true)}>
                  {t("IMPORT_FIELD_MODAL.FROM_FOLDER")}
                </BhFilterButton>
              </div>
            </div>
            {!loading && (
              <table className="w-full table-fixed">
                <BhTableHeader columns={tableColumnHeaders} sortedBy={filter.sortedBy} reversed={filter.asc} onClickCallback={changeFilter} />
                {importDataSorted &&
                  importDataSorted.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        {showRow(data) && (
                          <tbody key={index}>
                            <tr className="bh-table-row h-13 l-h-20px py-2">
                              <td>{data.companyName}</td>
                              <td>{formatDate(data.formDate)}</td>
                              <td>{data.formName}</td>
                            </tr>
                            <tr className="bh-table-row h-13 l-h-20px border-b py-2">
                              <td colSpan={2}>
                                <div className="bh-bg-smoke-50 mb-5 rounded p-3 text-justify">
                                  {!isAttachmentsField && data.fieldData}
                                  {isAttachmentsField && data.documents && data.documents.length > 0 && (
                                    <div className="flex flex-col">
                                      {data.documents.map((doc) => {
                                        return (
                                          <div className="mr-1 ml-1" key={doc.id}>
                                            <BhFileCardMedium fileEntity={doc} disabled={true} />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td className="align-top">
                                <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: () => importFieldDataToForm(data), disabled: data.added }}>
                                  {data.added ? t("FILE.ADDED") : t("IMPORT_FIELD_MODAL.TABLE_IMPORT_BUTTON")}
                                </BhSecondaryButton>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </React.Fragment>
                    );
                  })}
                <tbody>
                  <tr className="bh-table-row h-13 l-h-20px py-2">
                    <td colSpan={3}>
                      <div className="h-full w-full pt-10 text-center">
                        {importDataSorted && importDataSorted.length > 0 && (
                          <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: () => importFieldDataToForm(), disabled: importDataSorted.every((data) => data.added) }}>
                            {t("IMPORT_FIELD_MODAL.IMPORT_ALL_FIELDS")}
                          </BhSecondaryButton>
                        )}
                        {importDataSorted && importDataSorted.length === 0 && (
                          <div>
                            <h3>{t("GLOBAL.NO_DATA")}</h3>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            {loading && <BhLoadingIconContained />}
            {directorySelectionModalOpen && (
              <DirectorySelectionModal
                directoryIds={directorySelectionModalIds}
                preSelectedDirectoryIds={selectedFolderIds || []}
                modalHeader={t("MODAL.CHANGE_DIR.CHOOSE_DIR")}
                onModalSubmit={onFolderSelect}
                onModalClose={() => setDirectorySelectionModalOpen(false)}
                selectOnlyOne={false}
                allowOnlyParentsToBeSelected={false}
              />
            )}
          </div>
        </BhScrollableBody>
      }
      footer={
        <div>
          <BhTextOnlyButton buttonProps={{ onClick: closeModal }}>Sulge</BhTextOnlyButton>
        </div>
      }
    />
  );
};

export default ImportFieldInfoModal;
