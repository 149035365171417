import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import BhInputLabel from "@components/input/BhInputLabel";
import BhEmailInputWithUserDropdown from "@components/input/BhEmailInputWithUserDropdown";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType, IProject } from "@/model/IProject";
import { boSelectCompany } from "@/app/store/backofficeSlice";

interface Props {
  newProjectForm: IProject;
  setNewProjectForm: Dispatch<SetStateAction<IProject>>;
  companyId: EntityId;
}

const BONewProjectAdminInput: FC<Props> = ({ companyId, newProjectForm, setNewProjectForm }) => {
  const [emailInput, setEmailInput] = useState<string>("");
  const [users, setUsers] = useState<Array<ISimpleValidUserAuthority>>([]);
  const company = useAppSelector(boSelectCompany);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (company) {
      const projectAdministrators = (company && company.projects?.flatMap((project) => project.relatedUsers)) || [];
      const uniqueAdministrators = projectAdministrators.filter((admin, index, self) => {
        return index === self.findIndex((adm) => adm.userEntityId === admin.userEntityId);
      });
      setUsers(uniqueAdministrators);
    }
  }, []);

  const showValueCondition = (user: ISimpleValidUserAuthority) => {
    return !newProjectForm.relatedUsers.some((admin: ISimpleValidUserAuthority) => admin.userEntityId === user.userEntityId);
  };

  const addAdmin = () => {
    if (emailInput) {
      const usernameIsNotEmail = !emailInput.match(/\S+@\S+\.\S+/);
      if (usernameIsNotEmail) {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            children: "Invalid email address"
          })
        );
        return;
      }
      const adminToAdd = users.find((user) => user.username === emailInput) || ({ username: emailInput } as ISimpleValidUserAuthority);
      const updatedArray = [...newProjectForm.relatedUsers, adminToAdd];
      setNewProjectForm({ ...newProjectForm, relatedUsers: updatedArray });
      setEmailInput("");
    }
  };

  return (
    <>
      <BhInputLabel>Add administrators</BhInputLabel>
      <div className="flex items-center gap-2">
        <BhEmailInputWithUserDropdown emailInput={emailInput} setEmailInput={setEmailInput} values={users} showValueCondition={showValueCondition} placeholder="Add administrators" />
        <BhPrimaryButton buttonProps={{ onClick: addAdmin, disabled: !emailInput }}>Add</BhPrimaryButton>
      </div>
    </>
  );
};

export default BONewProjectAdminInput;
