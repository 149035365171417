import { EntityId } from "@reduxjs/toolkit";
import React, { lazy, useState } from "react";

const LazyXeokitWebViewer = lazy(() => import("@/views/home/project/detail/xeokit/XeokitWebViewer"));

interface Props {
  folderFileEntityId: EntityId;
}

const XeokitFullContainer = React.memo<Props>(({ folderFileEntityId }) => {
  const [xeokitViewerKey, setXeokitViewerKey] = useState(1);

  const resetViewer = () => {
    setXeokitViewerKey(Math.random());
  };

  return <LazyXeokitWebViewer folderFileEntityId={folderFileEntityId} resetViewer={resetViewer} key={xeokitViewerKey} />;
});

export default XeokitFullContainer;
