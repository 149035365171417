import React, { FC } from "react";
import { IProject } from "@/model/IProject";
import BhTableRow from "@components/table/BhTableRow";
import moment from "moment";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import BOProjectDropdown from "@components/backoffice/company/projects/BOProjectDropdown";
import { capitalizeFirstLetter } from "@/utilities/jsUtilities";
import { useAppDispatch } from "@/app/hooks";
import { boCloseProjectAsync, boSaveProjectLanguageAsync, boSuspendProjectAsync } from "@/app/store/backofficeSlice";
import { Authority, Resource } from "@/model/IUserAuthority";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { languageMap } from "@/views/home/profile/ProfileTabs/ProfileUserInfo";
import BhCombobox from "@components/input/BhCombobox";

interface Props {
  project: IProject;
  setAdministratorsModalOpen: Function;
}

const BOCompanyProjectTableRow: FC<Props> = ({ project, setAdministratorsModalOpen }) => {
  const dispatch = useAppDispatch();
  const projectUsers = Array.from(new Set(project.relatedUsers.map((user) => user.username)));
  const projectAdministrators = project.relatedUsers.filter((user) => user.authority === Authority.ADMIN && user.resource === Resource.PROJECT);
  const suspendProject = () => {
    const projectDTO: IProject = { ...project, suspended: !project.suspended };
    dispatch(boSuspendProjectAsync(projectDTO));
  };
  const closeProject = () => {
    const projectDTO: IProject = { ...project, closed: !project.closed };
    dispatch(boCloseProjectAsync(projectDTO));
  };

  const handleLanguageChange = (language: any) => {
    dispatch(boSaveProjectLanguageAsync({id: project.id, language: language.language} as IProject));
  };

  return (
    <BhTableRow key={project.id}>
      <td>{project.name}</td>
      <td>{project.status === "IN_PROGRESS" ? "In progress" : "Archived"}</td>
      <td>{moment(project.created).format("DD.MM.YY")}</td>
      <td>{projectUsers.length}</td>
      <td>
        <div className="flex items-center gap-1">
          {projectAdministrators.length} <BhIconButton icon={faEye} buttonProps={{ onClick: () => setAdministratorsModalOpen(project) }} />
        </div>
      </td>
      <td>{capitalizeFirstLetter(project.securityLevel)}</td>
      <td>
        <div className="pl-2">
          <BhCheckbox isChecked={project.suspended} onChange={suspendProject} property="suspended" large={true} />
        </div>
      </td>
      <td>
        <div className="pl-2">
          <BhCheckbox isChecked={project.closed} onChange={closeProject} property="closed" large={true} />
        </div>
      </td>
      <td>{project.billing.type}</td>
      <td>{project.billing.billingDay}</td>
      <td>{moment(project.billing.billingDate).format("DD.MM.YYYY")}</td>
      <td>
        <BhCombobox
          initialValue={project.language} onSelect={handleLanguageChange} values={Object.keys(languageMap)} property="language" placeholder={project.language} />
      </td>
      <td>
        <div className="relative flex justify-center ">
          <BOProjectDropdown project={project} />
        </div>
      </td>
    </BhTableRow>
  );
};

export default BOCompanyProjectTableRow;
