import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentUser, selectCurrentUserLanguage, selectCurrentUserTheme } from "@/app/store/userSlice";
import FullPageLogo from "@/views/global/FullPageLogo";
import { setDefaultLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import TimeAgo from "javascript-time-ago";
import et from "javascript-time-ago/locale/et.json";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import lt from "javascript-time-ago/locale/lt.json";
import lv from "javascript-time-ago/locale/lv.json";
import fi from "javascript-time-ago/locale/fi.json";

export const languageStore = {
  _data: "",

  get() {
    return this._data || "et";
  },

  set(value: string) {
    this._data = value;
  }
};

interface Props {
  children: React.ReactNode;
}

// @ts-ignore
const RequireAuth: FC<Props> = ({ children }) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const theme = useAppSelector(selectCurrentUserTheme);
  const language = useAppSelector(selectCurrentUserLanguage);
  const [userLanguageSet, setUserLanguageSet] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (language && language.length > 0) {
      const lang = language.split("_")[0];
      if (i18n.language === lang) {
        TimeAgo.setDefaultLocale(lang);
        setDefaultLocale(lang);
        setUserLanguageSet(true);
        return;
      }
      lang &&
        i18n.changeLanguage(lang, () => {
          setUserLanguageSet(true);
        });

      if (language === "en_EN") {
        TimeAgo.addLocale(en);
      }
      if (language === "ru_RU") {
        TimeAgo.addLocale(ru);
      }
      if (language === "lt_LT") {
        TimeAgo.addLocale(lt);
      }
      if (language === "lv_LV") {
        TimeAgo.addLocale(lv);
      }
      if (language === "fi_FI") {
        TimeAgo.addLocale(fi);
      }
      TimeAgo.setDefaultLocale(lang);

      languageStore.set(lang);

      setDefaultLocale(lang);
    }
  }, [language]);

  useEffect(() => {
    TimeAgo.addLocale(et);
    TimeAgo.setDefaultLocale("et");
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  if (currentUser.id && userLanguageSet && children) {
    return children;
  }

  return <FullPageLogo />;
};

export default RequireAuth;
