import React, { FC, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import BhBadge, { BhBadgeType } from "@components/badge/BhBadge";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";

interface Props {
  children?: React.ReactNode;
  selectedValues?: number;
  onReset?: Function;
  onClick?: Function;
  isLoading?: boolean;
  disabled?: boolean;
}

const BhFilterButton: FC<Props> = ({ children, selectedValues, onReset, onClick, isLoading, disabled }) => {
  const greaterThanZero = selectedValues ? selectedValues > 0 : false;
  let classes = "py-1.5 px-2 space-x-1.5 bh-text-deep-ocean-80 rounded border";
  classes += greaterThanZero ? " hover:bh-bg-mint-110 bh-border-dark-jungle bh-bg-mint" : " hover:bh-bg-pigeon-20 bh-border-white";

  const onResetCallback = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (onReset) onReset();
    },
    [onReset]
  );

  return (
    <button type="button" className={classes} onClick={() => onClick && onClick()} disabled={disabled}>
      <div className="flex flex-row items-center">
        {greaterThanZero && (
          <div className="mr-1.5">
            <BhBadge type={BhBadgeType.DARK}>{selectedValues}</BhBadge>
          </div>
        )}
        <span className="l-h-24px text-nowrap">{children}</span>
        {!greaterThanZero && <FontAwesomeIcon icon={isLoading ? faSpinnerThird : faCaretDown} className="ml-2" />}
        {greaterThanZero && onReset && (
          <div className="hover:bh-bg-deep-ocean hover:bh-text-white ml-1 inline-block h-6 w-6 rounded-full p-1" onClick={(e) => onResetCallback(e)}>
            <FontAwesomeIcon icon={faXmark} className="w-3.75 h-3.5 align-baseline" />
          </div>
        )}
      </div>
    </button>
  );
};

export default BhFilterButton;
