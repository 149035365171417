import React, { ChangeEvent, FC } from "react";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";
import { IFileEntity } from "@/model/files/IFileEntity";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentFormFileEntityId, selectValueByPropertyFromCurrentFormData, setFormModalsField, toggleFormModalsAttachmentField, toggleFormModalsOpen } from "@/app/store/form/formSlice";
import { EntityId } from "@reduxjs/toolkit";
import { FormType } from "@/model/IForm";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { formatDateTimeInternationalFormat } from "@/utilities/dateUtility";
import { fetchFormsOfTypeSince } from "@/api/form/formAPI";
import BhInputLabel from "@components/input/BhInputLabel";
import BhDropFileContainer from "@/components/upload/BhDropFileContainer";
import { uploadAttachmentFileAsync } from "@/app/store/uploadSlice";
import BhFormUploadContainer from "@components/upload/BhFormUploadContainer";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useTranslation } from "react-i18next";

interface Props {
  field: any;
  saveCallback?: Function;
  disabled: boolean;
}

const FormAttachmentsFieldContent: FC<Props> = ({ field, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const attachments = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, field.property));
  const formFileEntityId = useAppSelector(selectCurrentFormFileEntityId);
  const projectId = useAppSelector(selectCurrentProjectId);
  const docDate = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "docDate"));
  const importKTA = field.value.importKTA;

  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>{t("FORM.REMOVE_ATTACHMENT.MODAL.HEADER")}</h2>}
      body={<div>{t("FORM.REMOVE_ATTACHMENT.MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        const changedObject = { [field.property]: [] };
        saveCallback && saveCallback(changedObject);
        hideDeleteConfirmationModal();
      }}
    />
  ));

  const addFilesToRow = (files: Array<IFileEntity>) => {
    const attachmentsToSave = [...(attachments || []), ...files].map((val: any) => ({ ...val, selected: false }));
    const changedObject = {
      [field.property]: attachmentsToSave
    };
    saveCallback && saveCallback(changedObject, "", true);
  };

  const uploadFiles = async (files: any) => {
    if (disabled) return;
    let savedFiles: Array<IFileEntity> = [];
    await Promise.all(
      files.map(async (file: any) => {
        await dispatch(uploadAttachmentFileAsync({ file: file, projectId: projectId, parentFileEntityId: formFileEntityId })).then((action) => {
          const savedFileEntity = action.payload as IFileEntity;
          if (savedFileEntity && savedFileEntity.id) {
            savedFiles.push(savedFileEntity);
          }
        });
      })
    ).then(() => {
      if (savedFiles.length > 0) addFilesToRow(savedFiles);
    });
  };

  const onUploadFilesSelected = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const files = [...event.target.files];
    if (!files) return;
    const filesWithSizeLargerThanZero = files.filter((file) => file.size > 0);
    event.target.value = "";
    uploadFiles(filesWithSizeLargerThanZero);
  };

  const addUploadedOrAddedFiles = (attachmentObject: { files: Array<IFileEntity> }) => {
    const addedAttachments = attachmentObject.files.filter((file) => {
      return !attachments.some((att: IFileEntity) => att.id === file.id);
    });
    addFilesToRow(addedAttachments);
  };

  const removeAttachment = (fileId: EntityId) => {
    const updatedAttachments = attachments.filter((file: IFileEntity) => file.id !== fileId);
    const changedObject = {
      [field.property]: updatedAttachments
    };
    saveCallback && saveCallback(changedObject);
  };

  const openImportModal = () => {
    dispatch(toggleFormModalsOpen({ modal: "importFieldInfoModal" }));
    dispatch(setFormModalsField(field.property));
    dispatch(toggleFormModalsAttachmentField(true));
  };

  const fetchKTAForms = () => {
    const since = formatDateTimeInternationalFormat(docDate, false, true);
    const formTypes = [FormType.KTA_UUS, FormType.VCW];
    return fetchFormsOfTypeSince(projectId, since, formTypes).then((result) => {
      const formsNotAlreadyExisting = result.filter((formFileEntity) => {
        return ![field.property].some((f: IFileEntity) => {
          return f.id === formFileEntity.id;
        });
      });
      const formsObject = { [field.property]: formsNotAlreadyExisting };
      saveCallback && saveCallback(formsObject);
    });
  };

  return (
    <>
      {field.value?.label ? <BhInputLabel>{field.value.label}</BhInputLabel> : <div className="mt-4" />}
      {attachments?.length > 0 ? (
        <div className="w-full">
          <BhDropFileContainer onDrop={uploadFiles} disabled={disabled}>
            <div className="bh-bg-smoke bh-border-pigeon-50 flex flex-row items-center gap-x-3 rounded border border-dashed px-4 pt-4 pb-2">
              <AttachmentsContainerLarge
                attachments={attachments}
                property={"files"}
                saveCallback={addUploadedOrAddedFiles}
                removeCallback={!disabled ? removeAttachment : undefined}
                chooseFromProjectAllowed={!!projectId}
                multiple={true}
                addingDisabled={disabled}
                uploadCallback={uploadFiles}
                importCallback={field.value?.import ? () => openImportModal() : undefined}
                dropdownPosition={BhDropdownPositionEnum.BOTTOM_RIGHT}
              />
            </div>
          </BhDropFileContainer>
          <div className="mt-1 flex w-full flex-row items-center justify-end">
            <BhTextOnlyButton icon={faTrash} buttonProps={{ onClick: () => showDeleteConfirmationModal(), disabled: disabled }}>
              {t("FORM.REMOVE_ATTACHMENTS")}
            </BhTextOnlyButton>
          </div>
        </div>
      ) : (
        <BhFormUploadContainer
          disabled={disabled}
          projectId={projectId}
          importModalCallback={field.value?.import && openImportModal}
          fetchFormsCallback={importKTA ? fetchKTAForms : undefined}
          onDropCallback={uploadFiles}
          uploadFilesCallback={onUploadFilesSelected}
          addFilesCallback={addFilesToRow}
        />
      )}
    </>
  );
};

export default FormAttachmentsFieldContent;
