import React, { FC, lazy, useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import ProjectLogContainer from "@/views/home/project/detail/projectLog/ProjectLogContainer";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchProjectAdministratorsAsync, selectCurrentProjectCompanyId, selectCurrentProjectId, selectProjectStatus } from "@/app/store/project/projectSlice";
import Sidebar from "@/views/home/project/sidebar/Sidebar";
import OverviewContainer from "@/views/home/project/detail/overview/OverviewContainer";
import UsersContainer from "@/views/home/project/detail/users/UsersContainer";
import DirectoryContainer from "@/views/home/project/detail/directory/DirectoryContainer";
import NotFound from "@/views/global/NotFound";
import ProjectSettingsContainer from "@/views/home/project/detail/projectSettings/ProjectSettingsContainer";
import ProjectModals from "@/views/home/project/projectModals/ProjectModals";
import TaskBoardContainer from "@/views/home/project/detail/taskBoard/TaskBoardContainer";
import ContainerContainer from "@/views/home/project/detail/container/ContainerContainer";
import PartyContainer from "@/views/home/project/detail/users/parties/party/PartyContainer";
import ConfirmationRouter from "@/views/home/project/detail/confirmations/confirmation/router/ConfirmationRouter";
import ShareboxListContainer from "@/views/home/project/detail/shareboxes/ShareboxListContainer";
import ConfirmationsListContainer from "@/views/home/project/detail/confirmations/list/ConfirmationsListContainer";
import ActsAndContractsListContainer from "@/views/home/project/detail/actsAndContracts/ActsAndContractsListContainer";
import ChecklistContainer from "@/views/home/project/detail/checklists/checklist/ChecklistContainer";
import AsyncJobsController from "@/views/home/project/asyncJobs/AsyncJobsController";
import ContractRouter from "@/views/home/project/detail/actsAndContracts/router/ContractRouter";
import CreatePartyContainer from "@/views/home/project/detail/users/parties/CreatePartyContainer";
import { EntityId } from "@reduxjs/toolkit";
import SearchResultsContainer from "@/views/home/project/detail/search/SearchResultsContainer";
import UserNotificationsContainer from "@/views/home/project/detail/userNotifications/UserNotificationsContainer";
import { fetchUserNotificationsAsync } from "@/app/store/userNotificationsSlice";
import BaudriveContainer from "@/views/home/project/detail/baudrive/BaudriveContainer";
import SignInvites from "@/views/home/project/detail/signInvites/SignInvites";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import NavbarProject from "@/views/home/navbar/NavbarProject";
import ProjectResizer from "@/views/home/project/ProjectResizer";
import SupportContainer from "@/views/home/project/detail/support/SupportContainer";
import { fetchUserPendingFileConfirmationsCountAsync } from "@/app/store/fileConfirmation/fileConfirmationsSlice";
import NewFormContainerForOldFE from "@/views/home/project/detail/form/NewFormContainerForOldFE";
import FormContainer from "@/views/home/project/detail/form/FormContainer";
import TaskContainerDedicated from "@/views/home/project/detail/task/TaskContainerDedicated";
import PersonalTaskContainerDedicated from "@/views/home/project/detail/task/PersonalTaskContainerDedicated";
import BhIFrameComponent from "@/views/global/BhIFrameComponent";
import { classNames } from "@/utilities/jsUtilities";
import ShareboxContainer from "@/views/home/project/detail/shareboxes/detail/ShareboxContainer";
import { SidebarItemType } from "@/model/ISidebarItem";
import TaskIdRedirect from "@/views/home/project/detail/task/TaskIdRedirect";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BimRouter from "@/views/home/project/bim/BimRouter";
import MaruDashboardContainer from "@/views/home/project/detail/maru/MaruDashboardContainer";
import ChecklistBaseContainer from "@/views/home/project/detail/checklists/base/ChecklistBaseContainer";
import ChecklistCategoriesContainer from "@/views/home/project/detail/checklists/ChecklistCategoriesContainer";
import ChecklistListContainer from "@/views/home/project/detail/checklists/ChecklistListContainer";
import ChecklistAdminContainer from "@/views/home/project/detail/checklists/ChecklistAdminContainer";
import FormsProjectSettings from "@components/form/settings/projectSettings/FormsProjectSettings";
import FormBaseContainer from "@/views/home/project/detail/form/base/FormBaseContainer";

interface Props {
  projectId: EntityId;
}

const LazyPdftronContainer = lazy(() => import("@/views/home/project/detail/pdftron/PdftronContainer"));
// Tekitas probleeme kui vaatest tagasi tulla, siis state ei useselector query'd ei töötanud enam korrektselt.

const ProjectFC: FC<Props> = ({ projectId }) => {
  const projectIdFromStore = useAppSelector(selectCurrentProjectId);
  const projectStatus = useAppSelector(selectProjectStatus);
  const companyId = useAppSelector(selectCurrentProjectCompanyId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProjectAdministratorsAsync(projectId));
    dispatch(fetchUserNotificationsAsync(projectId));
    dispatch(fetchUserPendingFileConfirmationsCountAsync(projectId));
  }, [projectId]);

  if (!projectIdFromStore || projectStatus !== BhStateStatusType.SUCCESS) {
    return null;
  }

  function DirectoryContainerWithParams() {
    const { dirId } = useParams();
    if (dirId) {
      const dirIdInt = parseInt(dirId);
      return <DirectoryContainer currentDirId={dirIdInt} />;
    }
    return <NotFound />;
  }

  function PdftronContainerWithParams() {
    const { fileEntityId, fileEntityUuid } = useParams();
    if (fileEntityId) {
      const fileEntityIdInt = parseInt(fileEntityId);
      return <LazyPdftronContainer key={fileEntityIdInt} fileEntityId={fileEntityIdInt} fileEntityUuid={fileEntityUuid} />;
    }
    return <NotFound />;
  }

  function FormContainerWithParams() {
    const { fileEntityId } = useParams();
    const { dirId } = useParams();
    if (fileEntityId && dirId) {
      const fileEntityIdInt = parseInt(fileEntityId);
      const dirIdInt = parseInt(dirId);
      return <FormContainer fileEntityId={fileEntityIdInt} dirId={dirIdInt} />;
    }
    return <NotFound />;
  }

  function NewFormContainerWithParams() {
    const { dirId } = useParams();
    if (dirId) {
      const dirIdInt = parseInt(dirId);
      return <NewFormContainerForOldFE dirId={dirIdInt} />;
    }
    return <NotFound />;
  }

  function ShareboxContainerWithParams() {
    const { shareboxId } = useParams();
    if (shareboxId) {
      const shareboxIdInt = parseInt(shareboxId);
      return <ShareboxContainer shareboxId={shareboxIdInt} />;
    }
    return <NotFound />;
  }

  function ContainerContainerWithParams() {
    const { fileEntityId } = useParams();
    if (fileEntityId) {
      const fileEntityIdInt = parseInt(fileEntityId);
      return <ContainerContainer fileEntityId={fileEntityIdInt} />;
    }
    return <NotFound />;
  }

  function PartyContainerWithParams() {
    const { partyId } = useParams();
    if (partyId) {
      const partyIdInt = parseInt(partyId);
      return <PartyContainer partyId={partyIdInt} />;
    }
    return <NotFound />;
  }

  function CreatePartyContainerWithParams() {
    const { projectId } = useParams();
    return projectId ? <CreatePartyContainer projectId={parseInt(projectId)} /> : <NotFound />;
  }

  function ConfirmationContainerWithParams() {
    const { confirmationId } = useParams();
    if (confirmationId) {
      const confirmationIdInt = parseInt(confirmationId);
      return <ConfirmationRouter confirmationId={confirmationIdInt} />;
    }
    return <NotFound />;
  }

  function ContractContainerWithParams() {
    const { partyId } = useParams();
    if (partyId) {
      const partyIdInt = parseInt(partyId);
      return <ContractRouter partyId={partyIdInt} />;
    }
    return <NotFound />;
  }

  function ChecklistContainerWithParams() {
    const { checklistId } = useParams();
    const { checklistRoundId } = useParams();
    if (checklistId && checklistRoundId) {
      const checklistIdInt = parseInt(checklistId);
      const checklistRoundIdInt = parseInt(checklistRoundId);
      return <ChecklistContainer checklistId={checklistIdInt} checklistRoundId={checklistRoundIdInt} />;
    }
    return <NotFound />;
  }

  function ChecklistBaseWithParams() {
    const { checklistBaseId } = useParams();
    const { checklistBaseVersionId } = useParams();
    const { projectId } = useParams();
    if (checklistBaseId && checklistBaseVersionId && projectId) {
      const checklistBaseIdInt = parseInt(checklistBaseId);
      const checklistBaseVersionIdInt = parseInt(checklistBaseVersionId);
      const projectIdInt = parseInt(projectId);
      return <ChecklistBaseContainer companyId={companyId} checklistBaseId={checklistBaseIdInt} checklistBaseVersionId={checklistBaseVersionIdInt} projectId={projectIdInt} />;
    }
    return <NotFound />;
  }

  function FormBaseWithParams() {
    const { projectId } = useParams();
    const { formBaseId } = useParams();
    const { formBaseVersionId } = useParams();
    if (projectId && formBaseId && formBaseVersionId) {
      const projectIdInt = parseInt(projectId);
      const formBaseIdInt = parseInt(formBaseId);
      const formBaseVersionIdInt = parseInt(formBaseVersionId);
      return <FormBaseContainer companyId={companyId} projectId={projectIdInt} formBaseId={formBaseIdInt} formBaseVersionId={formBaseVersionIdInt} />;
    }
    return <NotFound />;
  }

  function TaskContainerWithParams() {
    const { taskIdentifier } = useParams();
    if (taskIdentifier) {
      return <TaskContainerDedicated taskIdentifier={taskIdentifier} />;
    }
    return <NotFound />;
  }

  function TaskIdRedirectWithParams() {
    const { taskId } = useParams();
    if (taskId) {
      return <TaskIdRedirect taskId={taskId} />;
    }
    return <NotFound />;
  }

  function PersonalTaskContainerWithParams() {
    const { taskId } = useParams();
    if (taskId) {
      const taskIdInt = parseInt(taskId);
      return <PersonalTaskContainerDedicated taskId={taskIdInt} />;
    }
    return <NotFound />;
  }

  function ReportWithParams() {
    const { reportId } = useParams();
    if (reportId) {
      const reportIdInt = parseInt(reportId);
      return (
        <BhIFrameComponent
          src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/mr/report/${reportIdInt}/react`}
          hideSidebar={true}
          sidebarItem={SidebarItemType.MR_BUDGET}
        />
      );
    }
    return <NotFound />;
  }

  function FourRReportWithParams() {
    const { reportId } = useParams();
    if (reportId) {
      const reportIdInt = parseInt(reportId);
      return (
        <BhIFrameComponent
          src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/mr/4r/report/${reportIdInt}/react`}
          hideSidebar={true}
          sidebarItem={SidebarItemType.MR_4R}
        />
      );
    }
    return <NotFound />;
  }

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <NavbarProject projectId={projectId} />
      <div className={classNames("flex h-full w-full flex-row overflow-hidden md:flex-col")}>
        <Sidebar />
        <div className="relative flex flex-1 overflow-hidden">
          {projectIdFromStore && (
            <Routes>
              <Route path="overview/*" element={<OverviewContainer />} />
              <Route path="taskboard/list/*" element={<TaskBoardContainer />} />
              <Route path="users/*" element={<UsersContainer />} />
              <Route path="users/party/new" element={<CreatePartyContainerWithParams />} />
              <Route path="users/party/:partyId/*" element={<PartyContainerWithParams />} />
              <Route path="dir/:dirId" element={<DirectoryContainerWithParams />} />
              <Route path="dir/:dirId/form/:fileEntityId" element={<FormContainerWithParams />} />
              <Route path="dir/:dirId/form/new" element={<NewFormContainerWithParams />} />
              <Route path="forms/settings" element={<FormsProjectSettings />} />
              <Route path="form/base/:formBaseId/*" element={<FormBaseWithParams />} />
              <Route path="form/base/:formBaseId/version/:formBaseVersionId" element={<FormBaseWithParams />} />
              <Route path="bim/*" element={<BimRouter />} />
              <Route path="sign/*" element={<SignInvites />} />
              <Route path="shareboxes/*" element={<ShareboxListContainer />} />
              <Route path="sharebox/:shareboxId/*" element={<ShareboxContainerWithParams />} />
              <Route path="container/:fileEntityId" element={<ContainerContainerWithParams />} />
              <Route path="confirmations/*" element={<ConfirmationsListContainer />} />
              <Route path="confirmation/:confirmationId/*" element={<ConfirmationContainerWithParams />} />
              <Route path="contracts" element={<ActsAndContractsListContainer />} />
              <Route path="contract/:partyId/*" element={<ContractContainerWithParams />} />
              <Route path="checklists" element={<ChecklistCategoriesContainer />} />
              <Route path="checklists/list/*" element={<ChecklistListContainer />} />
              <Route path="checklists/admin/*" element={<ChecklistAdminContainer />} />
              <Route path="checklists/:checklistId/round/:checklistRoundId" element={<ChecklistContainerWithParams />} />
              <Route path="checklist/base/:checklistBaseId/*" element={<ChecklistBaseWithParams />} />
              <Route path="checklist/base/:checklistBaseId/version/:checklistBaseVersionId" element={<ChecklistBaseWithParams />} />
              <Route path="mr/dashboard" element={<MaruDashboardContainer />} />
              <Route path="settings/*" element={<ProjectSettingsContainer />} />
              <Route path="log/*" element={<ProjectLogContainer />} />
              <Route path="view/:fileEntityId" element={<PdftronContainerWithParams />} />
              <Route path="view/:fileEntityId/:fileEntityUuid" element={<PdftronContainerWithParams />} />
              <Route path="user/notifications" element={<UserNotificationsContainer />} />
              <Route path="baudrive" element={<BaudriveContainer />} />
              <Route path="support" element={<SupportContainer />} />
              <Route path="search/*" element={<SearchResultsContainer />} />
              <Route path="task/:taskIdentifier" element={<TaskContainerWithParams />} />
              <Route path="task/id/:taskId" element={<TaskIdRedirectWithParams />} />
              <Route path="personal/task/:taskId" element={<PersonalTaskContainerWithParams />} />
              {/*CUSTOM CLIENTS*/}
              <Route
                path="budget"
                element={
                  <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/budget/react`} hideSidebar={true} sidebarItem={SidebarItemType.BUDGET} />
                }
              />
              <Route
                path="budget/v2"
                element={
                  <BhIFrameComponent
                    src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/budget/v2/react`}
                    hideSidebar={true}
                    sidebarItem={SidebarItemType.BUDGET_V2}
                  />
                }
              />
              <Route
                path="mr/budget"
                element={
                  <BhIFrameComponent
                    src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/mr/budget/react`}
                    hideSidebar={true}
                    sidebarItem={SidebarItemType.MR_BUDGET}
                  />
                }
              />
              <Route
                path="mr/reports"
                element={
                  <BhIFrameComponent
                    src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/mr/reports/react`}
                    hideSidebar={true}
                    sidebarItem={SidebarItemType.MR_BUDGET}
                  />
                }
              />
              <Route path="mr/report/:reportId" element={<ReportWithParams />} />
              <Route
                path="mr/daily/reports"
                element={
                  <BhIFrameComponent
                    src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/mr/daily/reports/react`}
                    hideSidebar={true}
                    sidebarItem={SidebarItemType.MR_BUDGET}
                  />
                }
              />
              <Route
                path="mr/daily/4r/reports"
                element={
                  <BhIFrameComponent
                    src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/mr/daily/4r/reports/react`}
                    hideSidebar={true}
                    sidebarItem={SidebarItemType.MR_4R}
                  />
                }
              />
              <Route
                path="mr/4r"
                element={
                  <BhIFrameComponent src={ConfigSingleton.getInstance().getConfig().REACT_APP_OLD_FE + `/project/${projectId}/mr/4r/react`} hideSidebar={true} sidebarItem={SidebarItemType.MR_4R} />
                }
              />
              <Route path="mr/4r/report/:reportId" element={<FourRReportWithParams />} />
            </Routes>
          )}
        </div>
        <ProjectModals />
        <AsyncJobsController />
        <ProjectResizer />
      </div>
    </div>
  );
};

const Project = React.memo(ProjectFC);
export default Project;
