import React, { FC } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { IFileTree } from "@/model/files/IFileTreeTemplate";

interface Props {
  fileTree: IFileTree;
}

const NewPartyTemplateFileTreeContainer: FC<Props> = ({ fileTree }) => {
  const file = true;

  return (
    <>
      <div className={classNames(fileTree && "bh-bg-mint-110", "hover:bh-bg-mint-50 group block rounded-sm")}>
        <div className="flex h-7 items-center py-2 px-2">
          <input
            className={classNames("h-4 w-4", "mr-2 rounded-sm")}
            type="checkbox"
            checked={file}
            onChange={() => {
              console.log(file);
            }}
          />
          <span
            className="flex w-5"
            onClick={() => {
              console.log(fileTree);
            }}
          >
            <FontAwesomeIcon className="h-3 w-3 cursor-pointer" icon={file ? faCaretDown : faCaretRight} aria-hidden="true" />
          </span>
          <FontAwesomeIcon className={classNames(file ? "bh-text-bauhub-green-120" : "bh-text-pigeon-60", "w-4", "mr-1.5")} icon={faFolder} aria-hidden="true" />
          <div className={classNames(" flex flex-1 overflow-hidden truncate")}>
            <a className={classNames("cursor-pointer", file ? "font-bold" : "")} onClick={() => console.log(fileTree)}>
              {fileTree?.name}
            </a>
          </div>
        </div>
      </div>
      {fileTree?.children?.map((subFile) => {
        return <NewPartyTemplateFileTreeContainer fileTree={subFile} key={subFile.id} />;
      })}
    </>
  );
};

export default NewPartyTemplateFileTreeContainer;
