import { ProjectInvoiceType } from "@/model/IBillingInfo";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { EntityId } from "@reduxjs/toolkit";
import { IProjectBilling } from "@/model/billing/IProjectBilling";

export interface IProject {
  id: EntityId;
  name: string;
  bucketName: string;
  companyId: EntityId;
  country: string;
  city: string;
  street: string;
  house: string;
  state?: string;
  zipCode: string;
  status: ProjectStatus;
  contractNumber: string;
  bauhubS3: boolean;
  uuid: string;
  securityLevel: ProjectSecurityLevel;
  suspended: boolean;
  closed: boolean;
  planRootDirectoryId: EntityId;
  documentRootDirectoryId: EntityId;
  bimDirectoryId: EntityId;
  userFileAuthorityIds: Array<EntityId>;
  pinned: boolean;
  createdByFullName: string;
  updatedByFullName: string;
  created: Date;
  updated: Date;
  billingSetupDone: boolean;
  billing: IProjectBilling;
  trialProject: boolean;
  confirmed: boolean;
  billingModel: ProjectInvoiceType;
  price: number;
  billingDate: Date;
  archivedDate: Date;
  relatedUsers: Array<ISimpleValidUserAuthority>;
  modules: Array<IProjectModule>;
  plan: BauhubPlan;
  language: string;
}

export enum ProjectStatus {
  IN_PROGRESS = "IN_PROGRESS",
  ARCHIVED = "ARCHIVED"
}

export enum BauhubPlan {
  LIGHT = "LIGHT",
  PRO = "PRO"
}

export enum ProjectSecurityLevel {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW"
}

export enum BauhubBannerType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
  ANNOUNCEMENT = "ANNOUNCEMENT",
  ANNOUNCEMENT_LIGHT = "ANNOUNCEMENT_LIGHT",
  ANNOUNCEMENT_BLUE = "ANNOUNCEMENT_BLUE"
}

export interface IProjectModule {
  id: EntityId;
  projectId: EntityId;
  module: Module;
  settings: any;
  adminSettings: any;
  created: Date;
  createdBy: string;
  updated: Date;
  updatedBy: string;
}

export enum Module {
  // RT budget
  TRESOOR_BUDGET = "TRESOOR_BUDGET",
  // RT v2
  TRESOOR_BUDGET_V2 = "TRESOOR_BUDGET_V2",
  // if enabled, some forms will have editable object name (see BH-625)
  EDITABLE_OBJECT_NAME_IN_FORMS = "EDITABLE_OBJECT_NAME_IN_FORMS",
  // MARU Budget
  MR_BUDGET = "MR_BUDGET",
  // customFields form names
  CUSTOM_FORM_NAMES = "CUSTOM_FORM_NAMES",
  // temporary module for parties
  PARTIES = "PARTIES",
  // Forge viewer
  FORGE = "FORGE",
  // Company name for distinction
  COMPANY_NAME = "COMPANY_NAME",
  // nordecon module
  NORDECON = "NORDECON",
  // EMBACH module
  EMBACH = "EMBACH",
  // Contract html template
  CONTRACT_HTML_TEMPLATES = "CONTRACT_HTML_TEMPLATES",
  // Synchronizer
  SYNC = "SYNC",
  // PDFTRON
  PDFTRON = "PDFTRON",
  // Embach & NOBE cooperation projects
  EMBACH_NOBE_COOP = "EMBACH_NOBE_COOP",
  // Nordecon & Embach cooperation projects
  NORDECON_EMBACH_COOP = "NORDECON_EMBACH_COOP",
  // Nobe & Nordecon cooperation projects
  NOBE_NORDECON_COOP = "NOBE_NORDECON_COOP",
  // editable "project name" field in all forms if needed
  EDITABLE_PROJECT_NAME_IN_ALL_FORMS = "EDITABLE_PROJECT_NAME_IN_ALL_FORMS",
  // Module for RT to hide budget from some admins (BH-2565)
  RT_ENGINEERING_PM_ROLE = "RT_ENGINEERING_PM_ROLE",
  // temporary module for checklists
  CHECKLISTS = "CHECKLISTS",
  // temporary module for BIM Viewer
  BIM = "BIM",
  // Vilcon budget
  RAPID_BUDGET = "RAPID_BUDGET",
  EPARAKSTS = "EPARAKSTS"
}

export interface IProjectSecurityLevelRequest {
  id: EntityId;
  securityLevel: string;
}
